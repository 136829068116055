<script>
  import AppHeader from "@/components/Essentials/AppHeader.vue";
  import AppFooter from "@/components/Essentials/AppFooter.vue";
  import Content from "@/components/Honor/Content.vue";

  export default {
    name : 'HonorPage',
    components: {Content, AppFooter, AppHeader},
  }
</script>

<template>
  <AppHeader></AppHeader>
  <Content></Content>
  <AppFooter></AppFooter>
</template>
