<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/16/solid'
import { CheckIcon, TrashIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'

// State variables
const sections = ref([])  // To store the list of sections fetched from API
const selectedSection = ref(null)
const sectionName = ref('')
const sectionDescription = ref('')
const sectionUrl = ref('')
const selectedMember = ref(null)

const newSectionName = ref('')
const newSectionDescription = ref('')
const newSectionUrl = ref('')


const members = ref([])  // To store the list of members fetched from the selected section

const filteredMembers = computed(() => {
  return members.value.filter(member => member.id_section === selectedSection.value?.id)
})

// Control whether the create section form is shown
const showCreateSectionForm = ref(false)

// Fetch sections from API on component mount
onMounted(async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/sections/showlist')
    sections.value = response.data || [] // Assuming the response is an array of sections
    selectedSection.value = sections.value[0] // Select the first section by default
  } catch (error) {
    console.error('Error fetching sections:', error)
  }
})

watch(selectedSection, async (newSection) => {
  if (newSection) {
    // Set form fields based on the selected section
    sectionName.value = newSection.nom
    sectionDescription.value = newSection.description
    sectionUrl.value = newSection.url

    // Fetch members of the selected section
    try {
      const response = await axios.get(`https://api.meritemaritime-fnmm.com/sections/showmember/${newSection.id}`)
      members.value = response.data || [] // Populate the members array with fetched data

      // Set the first member as the selected member
      if (members.value.length > 0) {
        selectedMember.value = members.value[0]
      }
    } catch (error) {
      console.error('Error fetching members:', error)
    }
  }
})

const createSection = async () => {
  if (newSectionName.value && newSectionDescription.value && newSectionUrl.value) {
    try {
      const response = await axios.post('https://api.meritemaritime-fnmm.com/sections/addsection', {
        nom: newSectionName.value,
        description: newSectionDescription.value,
        url: newSectionUrl.value
      })
      console.log('Section créée avec succès:', response.data)
      
      // Ajouter la nouvelle section à la liste des sections après création
      sections.value.push(response.data)
      
      // Réinitialiser les champs
      newSectionName.value = ''
      newSectionDescription.value = ''
      newSectionUrl.value = ''
    } catch (error) {
      console.error('Erreur lors de la création de la section:', error)
    }
  }
}

// Function to update the member
const updateMember = async () => {
  if (selectedMember.value && selectedMember.value.id) {
    try {
      const response = await axios.put('https://api.meritemaritime-fnmm.com/members/updatemember', {
        id: selectedMember.value.id,
        name: selectedMember.value.name,
        role: selectedMember.value.role,
        url: selectedMember.value.url,
      });

      console.log(response.data.message); // Success message
    } catch (error) {
      console.error('Error updating member:', error);
    }
  }
};

// Function to update the section
const updateSection = async () => {
  if (selectedSection.value) {
    try {
      const response = await axios.put(`https://api.meritemaritime-fnmm.com/sections/updatesection`, {
        id: selectedSection.value.id,
        nom: sectionName.value,
        description: sectionDescription.value,
        url: sectionUrl.value
      })
      console.log('Section updated successfully:', response.data)
    } catch (error) {
      console.error('Error updating section:', error)
    }
  }
}
</script>


<template>
<div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
  <!-- List of sections -->
  <Listbox as="div" v-model="selectedSection">
    <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner une section</ListboxLabel>
    <div class="relative mt-2">
      <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-4 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
        <span class="col-start-1 row-start-1 truncate pr-6">{{ selectedSection?.nom || 'Sélectionner une section' }}</span>
        <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="section in sections" :key="section.id" :value="section" v-slot="{ active, selected }">
            <li :class="[active ? 'bg-green-600 text-white outline-none ' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ section.nom }}</span>
              <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="size-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>

  <button @click="showCreateSectionForm = !showCreateSectionForm" class="rounded-md mt-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
      Créer une section
    </button>
</div>

<div v-if="showCreateSectionForm" class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom section</label>
        <input type="text" v-model="newSectionName" id="name" placeholder="Nom de la section" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="description" class="text-gray-200">Description</label>
        <textarea v-model="newSectionDescription" id="description" placeholder="Description de la section" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">Lien du site</label>
        <input type="text" v-model="newSectionUrl" id="url" placeholder="Lien du site" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button @click="createSection" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Enregistrer
      </button>
      <button @click="showCreateSectionForm = false" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Annuler
      </button>
    </div>
  </div>

<div class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom section</label>
        <input type="text" v-model="sectionName" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="name" class="text-gray-200">Description</label>
        <textarea v-model="sectionDescription" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">Lien du site</label>
        <input type="text" v-model="sectionUrl" id="url" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button @click="updateSection" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>

  <!-- Listbox for selecting member -->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-20 border-gray-700 border">
    <Listbox as="div" v-model="selectedMember">
      <ListboxLabel class="block text-sm/6 font-medium text-gray-200">Sélectionner un membre</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton class="grid w-full cursor-default grid-cols-1 rounded-md bg-gray-700 py-2 pl-3 pr-2 text-left text-gray-200 outline outline-1 -outline-offset-1 outline-gray-700 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-green-600 sm:text-sm/6">
          <span class="col-start-1 row-start-1 flex items-center gap-3 pr-6">
            <img v-if="selectedMember?.img" :src="selectedMember.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
            <span class="block truncate">{{ selectedMember?.prenom }} {{ selectedMember?.nom }}</span>
          </span>
          <ChevronUpDownIcon class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" aria-hidden="true" />
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="member in filteredMembers" :key="member.idMember" :value="member" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-green-600 text-white outline-none' : 'text-gray-200', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <div class="flex items-center">
                  <img v-if="member.img" :src="member.img" alt="" class="size-10 shrink-0 rounded-full object-cover" />
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ member.prenom }} {{  member.nom }}</span>
                </div>
                <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <button type="submit" class="rounded-md mt-6 flex w-full justify-center bg-green-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Créer</button>
  </div>

  <!-- Member form -->
  <div class="bg-gray-900 p-6 rounded-xl shadow mt-6 border-gray-700 border">
    <div class="flex gap-6 flex-col">
      <div>
        <label for="name" class="text-gray-200">Nom complet</label>
        <input type="text" name="name" id="name" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="role" class="text-gray-200">Role</label>
        <input type="text" name="role" id="role" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div>
        <label for="url" class="text-gray-200">URL image</label>
        <input type="text" name="url" id="url" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-4">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      <button type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
      </button>
    </div>
  </div>
</template>
