<script setup>
import { ref, onMounted } from "vue";
import { TrashIcon } from "@heroicons/vue/20/solid";
import axios from 'axios';

// Refs for form data
const formData = ref({
  title: "",
  url: ""
});

// Refs for categories data
const categories = ref([]);
const categoryName = ref("");

// Function to fetch categories from the API
const fetchData = async () => {
  try {
    const response = await fetch("https://api.meritemaritime-fnmm.com/prix/listprixanditem");
    if (!response.ok) throw new Error("Erreur lors de la récupération des données");
    categories.value = await response.json();
  } catch (error) {
    console.error("Erreur API:", error);
  }
};

const deleteItem = async (itemId) => {
  try {
    // Envoi de la requête DELETE avec l'ID dans le body
    const response = await axios.delete('https://api.meritemaritime-fnmm.com/prix/deleteitem', {
      data: { id: itemId }, // ID dans le corps de la requête
    });
    
    if (response.status === 200) {
      console.log('Item supprimé avec succès!');
      // Re-fetch des catégories pour mettre à jour la liste après suppression
      await fetchData();
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'item:', error);
  }
};

const updateCategory = async (category) => {
  try {
    // Envoie de la requête PUT pour mettre à jour l'élément
    const response = await axios.put('https://api.meritemaritime-fnmm.com/prix/updateprix', {
      id: category.id,
      name: category.name,
    });

    if (response.status === 200) {
      console.log('Item mis à jour avec succès!');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour de l\'item:', error);
  }
};


const updateItem = async (texte) => {
  try {
    // Envoie de la requête PUT pour mettre à jour l'élément
    const response = await axios.put('https://api.meritemaritime-fnmm.com/prix/updateitem', {
      id: texte.id,
      nom: texte.nom,
      img: texte.img
    });

    if (response.status === 200) {
      console.log('Item mis à jour avec succès!');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour de l\'item:', error);
  }
};

const deletePrix = async (itemId) => {
  try {
    // Envoi de la requête DELETE avec l'ID dans le body
    const response = await axios.delete('https://api.meritemaritime-fnmm.com/prix/deleteprix', {
      data: { id: itemId }, // ID dans le corps de la requête
    });
    
    if (response.status === 200) {
      console.log('Item supprimé avec succès!');
      // Re-fetch des catégories pour mettre à jour la liste après suppression
      await fetchData();
    }
  } catch (error) {
    console.error('Erreur lors de la suppression de l\'item:', error);
  }
};




// Function to handle the category creation
const createCategory = async () => {
  try {
    const response = await fetch("https://api.meritemaritime-fnmm.com/prix/prixcreate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: categoryName.value }),
    });

    if (!response.ok) throw new Error("Erreur lors de la création de la catégorie");

    // After creating the category, fetch the updated list
    await fetchData();
    
    // Optionally, reset the category name input
    categoryName.value = "";
  } catch (error) {
    console.error("Erreur API:", error);
  }
};

const submitForm = async (categoryId) => {
  try {
    // Ajouter l'ID à formData
    const dataToSend = {
      ...formData.value,
      categoryId: categoryId // Ou tout autre ID que tu veux ajouter
    };

    const response = await axios.post('https://api.meritemaritime-fnmm.com/prix/createitem', dataToSend);

    if (response.status === 200) {
      console.log('Item ajouté avec succès!');
      formData.value.title = '';
      formData.value.url = '';
    }
  } catch (error) {
    console.error('Erreur lors de l\'ajout de l\'item:', error);
  }
};


// Call the API when the component is mounted
onMounted(fetchData);
</script>

<template>
  <div class="bg-gray-900 p-6 rounded-xl border-gray-700 border shadow mb-8">
    <h1 class="text-2xl text-gray-200 mb-6">Créer une catégorie</h1>
    <div class="col-span-full">
      <label for="title" class="block text-sm/6 font-medium text-white">Nom de la catégorie</label>
      <div class="mt-2">
        <input
          v-model="categoryName"
          type="text"
          name="title"
          id="title"
          placeholder="-"
          class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
        />
      </div>
    </div>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button
        @click="createCategory"
        type="button"
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Créer
      </button>
    </div>
  </div>

  <div class="w-full flex flex-col">
    <ul v-if="categories.length" role="list" class="mx-auto grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1 lg:mx-0 lg:grid-cols-1 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li v-for="category in categories" :key="category.id" class="bg-gray-900 p-6 rounded-xl border-gray-700 border flex flex-col gap-6">
        <h1 class="text-gray-200 mb-4 text-2xl">{{ category.name }}</h1>
        <div class="border-gray-700 border-b pb-6">
          <label class="text-gray-200">Nom de la catégorie</label>
          <input v-model="category.name" type="text" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
          <div class="flex justify-end"> 
            <button @click="updateCategory(category)" type="button" class="rounded-md bg-green-600 px-3 py-2 mt-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
          Enregistrer
        </button>
      </div>   
    </div>
    

        <div class="bg-gray-800 p-6 border-gray-700 border shadow rounded-xl">
  <div class="items-center gap-4 mt-6 grid grid-cols-1 lg:grid-cols-2">
    <div class="w-full flex flex-col">
      <label class="text-gray-200">Titre</label>
      <input v-model="formData.title" type="text" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
    </div>
    <div class="w-full flex flex-col">
      <label class="text-gray-200">URL</label>
      <input v-model="formData.url" type="text" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
    </div>
  </div>
  <div class="mt-4 flex items-center justify-end gap-x-6">
    <!-- Passe l'ID de la catégorie (par exemple) ici -->
    <button @click="submitForm(category.id)" type="button" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
      Ajouter
    </button>
  </div>
</div>


        <!-- Loop through full texts -->
        <div v-for="texte in category.textes_complets" :key="texte.id" class="bg-gray-800 p-6 rounded-xl border border-gray-700 mt-6">
  <h1 class="text-gray-200 mb-4 text-xl">{{ texte.nom }}</h1>
  <img :src="texte.img" class="rounded-xl border-gray-700 border h-[160px]" />
  <div class="items-center gap-4 mt-6 grid grid-cols-1 lg:grid-cols-2">
    <div class="w-full flex flex-col">
      <label class="text-gray-200">Titre</label>
      <input v-model="texte.nom" type="text" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
    </div>
    <div class="w-full flex flex-col">
      <label class="text-gray-200">URL</label>
      <input v-model="texte.img" type="text" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"/>
    </div>
  </div>
  <div class="mt-6 flex items-center justify-end gap-x-4">
    <button @click="updateItem(texte)" type="button" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
      Enregistrer
    </button>
    <button @click="deleteItem(texte.id)" type="button" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
      <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
    </button>
  </div>
</div>


        <div class="justify-end flex">
          <button @click="deletePrix(category.id)" type="submit" class="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
            <TrashIcon class="w-5 h-5 shrink-0 text-gray-200 hover:text-red-400" aria-hidden="true"/>
          </button>
        </div>
      </li>
    </ul>

    <div class="mt-4 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Enregistrer
      </button>
    </div>
  </div>
</template>
