<script setup>
import { ref, onMounted } from 'vue';

const actions = ref([]); // Déclarez actions comme une référence réactive.

onMounted(async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/showcongre');
    if (!response.ok) {
      throw new Error('Échec de la récupération des données');
    }
    const data = await response.json();
    // Assurez-vous que les données que vous obtenez sont dans un format compatible avec `actions`
    actions.value = data.map(item => ({
      title: item.nom, // Remplacez 'nom' par le nom du champ approprié dans le JSON
      href: item.id // Remplacez 'id' par le champ approprié
    }));
  } catch (error) {
    console.error('Erreur API:', error);
  }
});
</script>

<template>
  <div class="bg-neutral-50 py-12">
    <div class="mx-auto max-w-7xl px-6">
      <div class="mt-8 lg:px-8 divide-y divide-gray-200 bg-white overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-1 sm:gap-px sm:divide-y-0">
        <div v-for="(action, actionIdx) in actions" :key="action.title" :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
          <div class="flex items-center justify-between">
            <h3 class="text-base font-semibold text-gray-900">
              <a :href="'congres-fnmm/' + action.href" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.title }}
              </a>
            </h3>
            <span class="pointer-events-none text-gray-300 group-hover:text-gray-400" aria-hidden="true">
              <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
