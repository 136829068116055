<script>
  import AppHeader from "@/components/Essentials/AppHeader.vue";
  import Signin from "@/components/Signin/Signin.vue";
  import AppFooter from "@/components/Essentials/AppFooter.vue";

  export default {
    name : 'SigninPage',
    components : {
      AppFooter,
      Signin,
      AppHeader

    }
  }
</script>

<template>
  <AppHeader></AppHeader>
  <Signin></Signin>
  <AppFooter></AppFooter>
</template>

<style scoped>

</style>