<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import MH from "@/components/Decores/MH.vue";
import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

export default {
  name: "DecoresPage",
  components : {
    Fastnav,
    MH,
    AppFooter,
    AppHeader

  }
}
</script>

<template>
  <AppHeader></AppHeader>
  <MH></MH>
  <Fastnav></Fastnav>
  <AppFooter></AppFooter>
</template>
