<template>
  <div class="bg-neutral-50 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Actualités</h2>

      <div v-if="posts.length" class="mx-auto mb-16 max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-1">
        <a           :href="'/nouvelles-de-la-federation/article/' + posts[0].id"
        class="flex flex-col items-start justify-between">
          
          <div class="relative w-full">
            <img :src="posts[0].url_image" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl mt-8">
            <div class="flex items-center gap-x-4 text-xs">
              <time :datetime="posts[0].datetime" class="text-gray-500">{{ posts[0].date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-2xl font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ posts[0].titre }}
                </span>
              </h3>
              <p class="mt-5 text-base text-gray-600">{{ posts[0].description }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a v-for="post in posts.slice(1, 4)" :key="post.id"           :href="'/nouvelles-de-la-federation/article/' + post.id"
        class="flex flex-col items-start justify-between">
          <div class="relative w-full">
            <img :src="post.url_image" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]" />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ post.titre }}
                </span>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm text-gray-600">{{ post.description }}</p>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const posts = ref([]);

const fetchArticles = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/federation/article');
    posts.value = response.data.map(article => ({
      id: article.id,
      titre: article.titre,
      description: article.description,
      url_image: article.url_image,
      url_pdf: article.url_pdf || null,
      datetime: null, // Ajoutez une date si elle est disponible
      date: null,     // Formattez la date si nécessaire
    }));
  } catch (error) {
    console.error('Erreur lors de la récupération des articles :', error);
  }
};

onMounted(fetchArticles);
</script>
