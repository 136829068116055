<script setup>
import { ref, watch } from 'vue'; // Importation des fonctionnalités nécessaires
import axios from 'axios'; // Pour effectuer les appels API

// Données réactives
const people = ref([]);
const searchQuery = ref('');
const isLoading = ref(false); // Indicateur de chargement
const errorMessage = ref(null); // Message d'erreur pour les résultats


// Timer pour le debounce
let debounceTimeout = null;

// Fonction pour effectuer un appel POST à l'API
const searchPeople = async () => {
  if (!searchQuery.value.trim()) {
    people.value = []; // Réinitialiser les résultats si la recherche est vide
    errorMessage.value = null;
    return;
  }

  isLoading.value = true;
  errorMessage.value = null;

  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/medailler/search', {
      type: 'mh', // Spécifiez le type pour l'API (à adapter si nécessaire)
      name: searchQuery.value,
    });

    // Mettre à jour les résultats en fonction de la réponse
    if (response.data && response.data.length > 0) {
      people.value = response.data.map((person) => ({
        name: person.Prenom,
        annee: person.Annee || '-',
        quartier: person.Quartier || '-',
        fonction: person.Fonction || '-',
      }));
    } else {
      people.value = [];
      errorMessage.value = 'Aucun résultat trouvé pour cette recherche.';
    }
  } catch (error) {
    console.error('Erreur lors de la recherche:', error);
    errorMessage.value = 'Une erreur est survenue lors de la recherche.';
  } finally {
    isLoading.value = false;
  }
};

// Surveiller `searchQuery` et appliquer un debounce
watch(searchQuery, (newValue) => {
  clearTimeout(debounceTimeout); // Réinitialiser le timer précédent
  debounceTimeout = setTimeout(() => {
    searchPeople(); // Lancer la recherche après le délai
  }, 500); // Délai de 500ms
});
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          LES DÉCORÉS MÉDAILLE D'HONNEUR
        </h2>
        <p class="mt-6 text-lg/8 text-gray-200">
          La liste des personnes décorées et médaillées est incomplète; cependant, elle sera mise à jour régulièrement afin de refléter les informations les plus récentes.
        </p>
        <div class="grid grid-cols-1 mt-6 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-4 mx-auto w-full">
          <a
            v-for="file in files"
            :key="file.name"
            :href="file.href"
            class="text-gray-200 hover:text-violet-600 shadow flex py-2 rounded justify-center items-center border"
          >
            {{ file.name }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white pt-16 pb-24 md:pb-32 lg:pb-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 px-6 lg:px-8 xl:grid-cols-1 mb-6">
      <label for="search" class="text-white px-4 py-2 bg-[#072653] rounded-tr-md rounded-tl-md">
        Rechercher
      </label>
      <input
        v-model="searchQuery"
        type="text"
        name="search"
        id="search"
        placeholder="Exemple : BOURDIER Jean"
        class="block w-full rounded-bl-md rounded-br-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
      />
    </div>
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-12 px-6 lg:px-8 xl:grid-cols-1">
      <div v-if="isLoading" class="text-center text-gray-500">Recherche en cours...</div>
      <div v-else-if="errorMessage" class="text-center text-red-600">{{ errorMessage }}</div>

      <ul
        v-if="people.length > 0"
        role="list"
        class="mx-auto w-full grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
      >
        <li
          v-for="(person, index) in people"
          :key="index"
          class="border p-2 rounded bg-neutral-50 shadow"
        >
          <h3 class="text-lg/8 font-semibold text-gray-900">{{ person.name }}</h3>
          <div class="grid sm:grid-cols-3 grid-cols-2">
            <div class="flex flex-col">
              <h4>Année</h4>
              <p class="text-base/7 text-gray-600">{{ person.annee }}</p>
            </div>
            <div class="flex flex-col">
              <h4>Quartier</h4>
              <p class="text-base/7 text-gray-600">{{ person.quartier }}</p>
            </div>
            <div class="flex flex-col">
              <h4>Fonction</h4>
              <p class="text-base/7 text-gray-600">{{ person.fonction }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
