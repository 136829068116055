<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import News from "@/components/Nouvelles/News.vue";
import Banner from "@/components/Nouvelles/Banner.vue";

export default {
  name: "Nouvelles",
  components: {Banner, News, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <News></News>
  <AppFooter></AppFooter>
</template>
