<template>
  <div class="bg-[#111827] py-12 shadow rounded-xl border-gray-700 border">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-2xl font-semibold tracking-tight text-white sm:text-3xl">Mise à jour</h2>
      </div>
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-700 mt-10 pt-10  lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <article v-for="post in posts" :key="post.id" class="flex max-w-xl flex-col items-start justify-between">
          <div class="flex items-center gap-x-4 text-xs">
            <time :datetime="post.datetime" class="text-gray-300">{{ post.date }}</time>
            <span class="relative z-10 rounded-full bg-red-500 text-white font-bold px-3 py-1.5">{{ post.category.title }}</span>
          </div>
          <div class="group relative">
            <h3 class="mt-3 text-lg/6 font-semibold text-white">
              <span>
                <span class="absolute inset-0" />
                {{ post.title }}
              </span>
            </h3>
            <p class="mt-5 line-clamp-3 text-sm/6 text-neutral-100">{{ post.description }}</p>
          </div>
          <div class="relative mt-8 flex items-center gap-x-4">
            <img :src="post.author.imageUrl" alt="" class="size-10 rounded-full bg-gray-50" />
            <div class="text-sm/6">
              <p class="font-semibold text-white">
                <span>
                  <span class="absolute inset-0" />
                  {{ post.author.name }}
                </span>
              </p>
              <p class="text-neutral-200">{{ post.author.role }}</p>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
const posts = [
      {
        id: 1,
        title: 'Refonte complète du site vitrine et du tableau de bord administratif.',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: '26 novembre 2024',
        datetime: '2020-03-16',
        category: {
          title: 'Important'},
        author: {
          name: 'Hervé Duong',
          role: 'High Base Code - CEO',
          imageUrl:
              'https://i.ibb.co/THGpLWj/h.jpg',
        },
      },
      {
        id: 2,
        title: 'Refonte complète de la structure de la Base De Données.',
        description:
            'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: '12 novembre 2024',
        datetime: '2020-03-16',
        category: {title: 'Important'},
        author: {
          name: 'Bastien Rapaud',
          role: 'High Base Code - CEO',
          imageUrl:
              'https://i.ibb.co/D816hpy/b.jpg',
        },
      },
    ]
</script>