<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">NOUVELLES DE LA FÉDÉRATION</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Restez informé des dernières actualités et initiatives de la Fédération Nationale du Mérite Maritime. Découvrez nos actions, événements, et engagements pour promouvoir et valoriser le mérite maritime.</p>
      </div>
    </div>
  </div>
</template>
