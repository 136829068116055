<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl lg:mx-0">
        <h2 class="text-pretty text-3xl font-semibold tracking-tight text-gray-200 mb-2 sm:text-3xl">BIENVENUE SUR LE SITE OFFICIEL DE LA</h2>
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">FÉDÉRATION NATIONALE DU MÉRITE MARITIME ET DE LA MÉDAILLE D'HONNEUR DES MARINS</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Retrouvez-nous sur les réseaux sociaux</p>
        <div  class="grid grid-cols-1 mt-6 sm:grid-cols-3 lg:grid-cols-5 gap-x-3 gap-y-4 mx-auto w-full">
          <a href="https://www.linkedin.com/company/fnmm/posts/?feedView=all" target="_blank" class="flex items-center gap-2 rounded-md border px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><rect width="4" height="12" x="2" y="9"/><circle cx="4" cy="4" r="2"/></svg>
            LinkedIn</a>
          <a href="https://www.instagram.com/merite_maritime/#" target="_blank" class="flex items-center gap-2 rounded-md border px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg>
            Instagram</a>
        </div>
      </div>
    </div>
  </div>
</template>
