<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">CONGRÈS</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Participez au congrès annuel de la FNMM, un lieu de rencontres et d’échanges où auteurs, éditeurs et passionnés de littérature se réunissent pour célébrer les grandes œuvres et débattre des enjeux du monde littéraire.</p>
      </div>
    </div>
  </div>
</template>
