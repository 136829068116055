<template>
    <form @submit.prevent="handleSubmit">
      <div class="space-y-12">
        <div class="pb-12">
  
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <label for="title" class="block text-sm/6 font-medium text-white">Titre</label>
              <div class="mt-2">
                <input v-model="form.title" type="text" name="title" id="title" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>
  
            <div class="col-span-full">
              <label for="url" class="block text-sm/6 font-medium text-white">URL image</label>
              <div class="mt-2">
                <input v-model="form.url" type="text" name="url" id="url" placeholder="-" class="block py-4 px-4 w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>
  
            <div class="col-span-full">
              <label for="about" class="block text-sm/6 font-medium text-white">Description</label>
              <div class="mt-2">
                <textarea v-model="form.about" id="about" name="about" rows="3" placeholder="-" class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" @click="closeForm" class="text-sm/6 font-semibold text-white">Annuler</button>
        <button type="submit" class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">Sauvegarder</button>
      </div>
    </form>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import { defineEmits } from 'vue';
  
  const emit = defineEmits();
  
  // Crée un objet "form" pour gérer les données du formulaire
  const form = ref({
    title: '',
    url: '',
    about: ''
  });
  
  // Fonction pour envoyer les données du formulaire via un appel API POST
  const handleSubmit = async () => {
    try {
      // Récupérer la valeur de "selectedSectionId" depuis le localStorage
      const selectedSectionId = localStorage.getItem('selectedSectionId');
  
      // Ajouter la valeur de "selectedSectionId" aux données du formulaire
      const requestData = {
        ...form.value,
        selectedSectionId: selectedSectionId || null, // si aucun ID n'est trouvé, on envoie "null"
      };
  
      // Envoi de la requête avec axios
      const response = await axios.put('https://api.meritemaritime-fnmm.com/sections/article/update', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Réponse API:', response.data);
      // Affiche un message de succès ou redirige l'utilisateur
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire:', error);
    }
  };
  
  // Fonction pour fermer le formulaire
  const closeForm = () => {
    emit('close');
  };
  
  // Récupérer l'article sélectionné depuis le localStorage et remplir le formulaire
  onMounted(() => {
    const storedPost = localStorage.getItem('selectedPost');
    if (storedPost) {
      const post = JSON.parse(storedPost); // Parse l'article JSON stocké dans localStorage
      form.value.title = post.title || '';
      form.value.url = post.img || ''; // Utilise l'URL de l'image stockée
      form.value.about = post.description || ''; // Utilise la description du post
    }
  });
  </script>
  