<template>
  <div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-[#111827] px-4 py-5 shadow sm:p-6 border-gray-700 border">
        <dt class="truncate text-sm font-medium text-gray-200">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-white">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const stats = ref([
  { name: 'Nombre total MM', stat: '' },
  { name: 'Nombre total MH', stat: '' },
  { name: 'Nombre total', stat: '' },
]);

// Fonction pour récupérer les données de l'API
const fetchData = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/medailler/data');
    const data = await response.json();

    // Mettre à jour les stats avec les données récupérées
    stats.value = [
      { name: 'Nombre total MM', stat: data.total_MHFNMM.toLocaleString() },
      { name: 'Nombre total MH', stat: data.total_MMFNMM.toLocaleString() },
      { name: 'Nombre total', stat: data.total.toLocaleString() },
    ];
  } catch (error) {
    console.error("Erreur lors de la récupération des données:", error);
  }
};

// Appel de la fonction au montage du composant
onMounted(() => {
  fetchData();
});
</script>
