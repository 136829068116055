<script setup>
import { ref, onMounted } from 'vue'

const presidents = ref([])
const members = ref([])

// Fonction pour récupérer les données depuis l'API
const fetchData = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/conseil/list')
    const data = await response.json()

    // Remplissage des données pour les présidents et les membres
    presidents.value = data.filter(item => item.rank === 1 || item.rank === 2)
    members.value = data.filter(item => item.rank > 2)
  } catch (error) {
    console.error('Erreur lors de la récupération des données :', error)
  }
}

// Fonction pour sauvegarder les modifications pour les présidents
const savePresidents = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/conseil/admin/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(presidents.value),
    })

    if (response.ok) {
      console.log('Modifications des présidents sauvegardées.')
    } else {
      console.error('Erreur lors de la sauvegarde des présidents.')
    }
  } catch (error) {
    console.error('Erreur lors de la sauvegarde des présidents :', error)
  }
}

// Fonction pour sauvegarder les modifications pour les membres
const saveMembers = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/conseil/admin/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(members.value),
    })

    if (response.ok) {
      console.log('Modifications des membres sauvegardées.')
    } else {
      console.error('Erreur lors de la sauvegarde des membres.')
    }
  } catch (error) {
    console.error('Erreur lors de la sauvegarde des membres :', error)
  }
}

// Chargement des données lorsque le composant est monté
onMounted(() => {
  fetchData()
})
</script>

<template>
  <div class="bg-gray-900 p-6 rounded-xl shadow border-gray-700 border">
    <div class="mx-auto max-w-2xl lg:mx-0 mb-4">
      <h2 class="text-pretty text-2xl font-semibold tracking-tight text-gray-200 sm:text-2xl">Présidents</h2>
    </div>
    <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-1 lg:mx-0 lg:grid-cols-2 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li
        v-for="(president, index) in presidents"
        :key="president.id"
        class="bg-gray-800 p-6 rounded-xl border-gray-700 border flex flex-col gap-6"
      >
        <div>
          <label for="name" class="text-gray-200">Nom complet</label>
          <input
            type="text"
            name="name"
            v-model="presidents[index].nom"
            id="name"
            placeholder="-"
            class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
        <div>
          <label for="role" class="text-gray-200">Rôle</label>
          <input
            type="text"
            name="role"
            v-model="presidents[index].role"
            id="role"
            placeholder="-"
            class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
        <div>
          <label for="bio" class="text-gray-200">Bio</label>
          <textarea
            name="bio"
            v-model="presidents[index].description"
            id="bio"
            placeholder="-"
            class="mt-2 max-h-[150px] min-h-20 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
      </li>
    </ul>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button
        type="button"
        @click="savePresidents"
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Enregistrer
      </button>
    </div>
  </div>

  <div class="bg-gray-900 p-6 rounded-xl shadow border-gray-700 border mt-10">
    <div class="mx-auto max-w-2xl lg:mx-0 mb-4">
      <h2 class="text-pretty text-2xl font-semibold tracking-tight text-gray-200 sm:text-2xl">Membres</h2>
    </div>
    <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-1 lg:mx-0 lg:grid-cols-2 lg:max-w-none lg:gap-x-8 xl:col-span-2">
      <li
        v-for="(member, index) in members"
        :key="member.id"
        class="bg-gray-800 p-6 rounded-xl border-gray-700 border flex flex-col gap-6"
      >
        <div>
          <label for="name" class="text-gray-200">Nom complet</label>
          <input
            type="text"
            name="name"
            v-model="members[index].nom"
            id="name"
            placeholder="-"
            class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
        <div>
          <label for="role" class="text-gray-200">Rôle</label>
          <input
            type="text"
            name="role"
            v-model="members[index].role"
            id="role"
            placeholder="-"
            class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
        <div>
          <label for="bio" class="text-gray-200">Bio</label>
          <textarea
            name="bio"
            v-model="members[index].description"
            id="bio"
            placeholder="-"
            class="mt-2 max-h-[150px] min-h-20 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
      </li>
    </ul>
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button
        type="button"
        @click="saveMembers"
        class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
      >
        Enregistrer
      </button>
    </div>
  </div>
</template>
