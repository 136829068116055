<script setup>
</script>

<template>
  <div class="p-8 mx-auto max-w-7xl text-gray-900">
    <h1 class="text-3xl font-bold mb-6">Conditions d’Utilisation de la Messagerie FNMM-MH</h1>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">1. Objet de la messagerie</h2>
      <p>
        La messagerie intégrée au site FNMM-MH est un outil destiné à faciliter les communications entre les utilisateurs et/ou avec les représentants de la FNMM-MH.
        Son utilisation doit se limiter aux sujets liés aux activités de la Fédération.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">2. Utilisation autorisée</h2>
      <p>En utilisant la messagerie, vous vous engagez à :</p>
      <ul class="list-disc ml-6">
        <li>Respecter les lois en vigueur.</li>
        <li>Ne pas envoyer de contenus illégaux (propos offensants, spam, liens illégaux).</li>
        <li>Garantir la véracité des informations transmises.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">3. Règles de conduite</h2>
      <ul class="list-disc ml-6">
        <li>Adopter un comportement respectueux.</li>
        <li>Ne pas harceler ou menacer d’autres utilisateurs.</li>
        <li>Ne pas utiliser la messagerie à des fins commerciales ou politiques.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">4. Confidentialité des échanges</h2>
      <p>
        Les messages échangés restent privés, sauf dans les cas suivants :
      </p>
      <ul class="list-disc ml-6">
        <li>Réponse à une demande légale.</li>
        <li>Suspicion d’utilisation abusive.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">5. Responsabilité des utilisateurs</h2>
      <p>Tout non-respect des règles peut entraîner :</p>
      <ul class="list-disc ml-6">
        <li>Un avertissement.</li>
        <li>Une suspension temporaire ou définitive.</li>
        <li>Un signalement aux autorités.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">6. Sécurité des données</h2>
      <p>
        FNMM-MH met en œuvre des mesures de sécurité pour protéger les échanges.
        Toutefois, il est recommandé de ne pas partager d’informations sensibles via ce canal.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">7. Sécurité des données</h2>
      <p>
        FNMM-Mh met en oeuvre des mesures de sécurité pour protéger les données échangées via la messagerie.
      </p>
      <p>
        Il est toutefois conseillé aux utilisateurs de ne pas partager d’informations sensibles (ex. : données financières, mots de passe) via ce canal.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">8. Conservation des messages</h2>
      <p>
        Les messages échangés via la messagerie FNMM-MH peuvent être conservés pendant une durée maximale de [durée, ex. : 12 mois] à des fins de suivi et de sécurité.
      </p>
      <p>
        Les utilisateurs peuvent demander la suppression de leurs messages conformément au RGPD (Règlement Général sur la Protection des Données) en contactant FNMM-MH.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">9. Modifications des conditions d’utilisation</h2>
      <p>
        FNMM-Mh se réserve le droit de modifier à tout moment ces conditions d’utilisation. Les modifications prendront effet dès leur publication sur le site. Les utilisateurs sont invités à consulter régulièrement cette page.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">10. Contact</h2>
      <p>Pour toute question, contactez notre support technique :</p>
      <address class="italic">
        FNMM-MH - Support technique<br />
        Immeuble CMCI – 2, rue Henri Barbusse – 13001 Marseille
      </address>
    </section>
  </div>
</template>

<style scoped></style>
