<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <Carousel></Carousel>
  <Fastnav></Fastnav>
  <Institu></Institu>
  <News></News>
  <Fastcontact></Fastcontact>
  <AppFooter></AppFooter>
</template>

<script>

import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Social from "@/components/Home/Social/Social.vue";
import Fastnav from "@/components/Home/FastNav/Fastnav.vue";
import Fastcontact from "@/components/Home/Contact/Fastcontact.vue";
import Carousel from "@/components/Home/Carousel/Carousel.vue";
import Institu from "@/components/Home/Institu/Institu.vue";
import News from "@/components/Home/News/News.vue";
import Banner from "@/components/Home/Banner/Banner.vue";

export default {
  name: 'Index',
  components: {
    Banner,
    News,
    Institu,
    Carousel,
    Fastcontact,
    Fastnav,
    Social,
    AppFooter,
    AppHeader
  }
}
</script>
