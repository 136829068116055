<template>
  <form @submit.prevent="handleSubmit">
    <div class="space-y-12">
      <div class="pb-12">
        <h2 class="text-base/7 font-semibold text-white">Lecture</h2>
        <p class="mt-1 text-sm/6 text-gray-400">Créer ou mettre à jour un article.</p>
        <div class="justify-end flex w-full">
          <button
            type="button"
            class="px-4 py-2 bg-gray-900 rounded-xl hover:bg-gray-700"
            @click="handleTrashClick"
          >
            <TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full">
            <label for="title" class="block text-sm/6 font-medium text-white">Titre</label>
            <div class="mt-2">
              <input
                type="text"
                name="title"
                id="title"
                v-model="article.titre"
                placeholder="-"
                class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
              />
            </div>
          </div>

          <div class="col-span-full">
            <label for="url" class="block text-sm/6 font-medium text-white">URL image</label>
            <div class="mt-2">
              <input
                type="text"
                name="url"
                id="url"
                v-model="article.url_image"
                placeholder="-"
                class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
              />
            </div>
          </div>

          <div class="col-span-full">
            <label for="about" class="block text-sm/6 font-medium text-white">Description</label>
            <div class="mt-2">
              <textarea
                id="about"
                name="about"
                rows="3"
                v-model="article.description"
                placeholder="-"
                class="block w-full rounded-md border-0 bg-white/5 py-4 px-4 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
              ></textarea>
            </div>
          </div>

          <div class="col-span-full">
            <label for="file-upload" class="block text-sm font-medium text-gray-300">Fichier</label>
            <label
              for="file-upload"
              class="mt-2 flex cursor-pointer justify-center rounded-lg border border-dashed border-gray-500 px-6 py-10"
            >
              <div class="text-center">
                <svg
                  class="mx-auto size-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 48 48"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M6 4h24l8 8v32H6V4z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <line
                    x1="12"
                    y1="20"
                    x2="32"
                    y2="20"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <line
                    x1="12"
                    y1="26"
                    x2="28"
                    y2="26"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <line
                    x1="12"
                    y1="32"
                    x2="24"
                    y2="32"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                </svg>
                <div class="mt-4 flex text-sm text-gray-300">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500"
                  >
                    <span>Choisir un fichier</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      @change="handleFileUpload"
                      class="sr-only"
                    />
                  </label>
                  <p class="pl-1">ou glisser-déposer ici</p>
                </div>
                <p class="mt-1 text-xs text-gray-400">Formats acceptés : PDF jusqu'à 10MB</p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="button" class="text-sm/6 font-semibold text-white" @click="$emit('cancel')">Annuler</button>
      <button
        type="submit"
        class="rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
      >
        Sauvegarder
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { TrashIcon } from '@heroicons/vue/20/solid';

const article = ref({
  titre: '',
  description: '',
  url_image: '',
  url_pdf: '',
});

const file = ref(null);

const checkFederationArticle = () => {
  const federationArticle = localStorage.getItem('federationArticle');
  if (federationArticle === '1') {
    const articleDetails = JSON.parse(localStorage.getItem('FederationArticleDetails') || '{}');
    if (articleDetails) {
      article.value = {
        titre: articleDetails.titre || '',
        description: articleDetails.description || '',
        url_image: articleDetails.url_image || '',
        url_pdf: articleDetails.url_pdf || '',
      };
    }
  }
};

const handleFileUpload = (event) => {
  file.value = event.target.files[0];
  console.log('Fichier sélectionné:', file.value);
};

const handleSubmit = async () => {
  const federationArticle = localStorage.getItem('federationArticle');
  try {
    if (federationArticle === '1') {
      // PUT request to update an article
      const response = await axios.put('https://api.meritemaritime-fnmm.com/federation/update/article', {
        id: JSON.parse(localStorage.getItem('FederationArticleDetails') || '{}').id,
        ...article.value,
      });
      console.log('Article mis à jour:', response.data);
    } else if (federationArticle === '0') {
      // POST request to create a new article
      const response = await axios.post('https://api.meritemaritime-fnmm.com/federation/article/create', article.value);
      console.log('Nouvel article créé:', response.data);
    }
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement:', error);
  }
};

const handleTrashClick = async () => {
  try {
    // Récupération de l'ID depuis le localStorage
    const storedArticle = JSON.parse(localStorage.getItem('FederationArticleDetails') || '{}');
    const id = storedArticle.id;

    if (!id) {
      console.error("ID de l'article manquant");
      return;
    }

    // Requête DELETE avec Axios
    const response = await axios.delete(`https://api.meritemaritime-fnmm.com/federation/delete/article/${id}`);
    
    console.log(response.data.message);

    // Optionnel: Supprimer l'article du localStorage après suppression
    localStorage.removeItem('FederationArticleDetails');

    // Optionnel: Rafraîchir la liste des articles
    checkFederationArticle();
    
  } catch (error) {
    console.error("Erreur lors de la suppression de l'article:", error);
  }
};

onMounted(() => {
  checkFederationArticle();
});
</script>
XD