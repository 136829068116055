<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

// États du composant
const name = ref(''); // Pour stocker le nom du prix
const isLoading = ref(true); // Pour gérer l'état de chargement
const error = ref(null); // Pour gérer les erreurs

// Récupérer l'id depuis l'URL
const route = useRoute();
const id = route.params.id;

// Fonction pour récupérer les détails du prix
const fetchPrixDetails = async () => {
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/prix/listprixselect/${id}`);
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des données');
    }
    const data = await response.json();
    name.value = data.name; // Mettre à jour le nom du prix
  } catch (err) {
    console.error('Erreur:', err);
    error.value = err.message; // Stocker le message d'erreur
  } finally {
    isLoading.value = false; // Désactiver l'état de chargement
  }
};

// Appeler la fonction fetchPrixDetails lorsque le composant est monté
onMounted(() => {
  fetchPrixDetails();
});
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px] items-center flex">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <!-- Afficher un message d'erreur s'il y en a un -->
        <div v-if="error" class="text-red-500">
          Erreur : {{ error }}
        </div>

        <!-- Afficher un indicateur de chargement pendant que les données sont récupérées -->
        <div v-else-if="isLoading" class="text-white">
          Chargement en cours...
        </div>

        <!-- Afficher le nom du prix une fois les données chargées -->
        <h2 v-else class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          {{ name }}
        </h2>
      </div>
    </div>
  </div>
</template>