<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { PaperClipIcon, TrashIcon } from "@heroicons/vue/20/solid";

// Stockage des liens du carrousel dans une variable réactive
const carouselLinks = ref([]);
const scrollDelay = ref(3000); // Valeur initiale du délai de défilement (par défaut 3000ms)

// Fonction pour récupérer les liens et les images via l'API
const fetchCarouselData = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/carrousel/image');
    carouselLinks.value = response.data; // Stocke les données dans la variable réactive
  } catch (error) {
    console.error("Erreur lors de la récupération des données du carrousel :", error);
    alert("Une erreur est survenue lors de la récupération des données");
  }
};

// Fonction pour récupérer le délai de défilement depuis l'API
const fetchScrollDelay = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/carrousel/timer');
    scrollDelay.value = response.data.time || 3000; // On récupère le délai, sinon on garde la valeur par défaut
  } catch (error) {
    console.error("Erreur lors de la récupération du délai de défilement :", error);
    alert("Une erreur est survenue lors de la récupération du délai de défilement");
  }
};

// Fonction pour soumettre le formulaire avec l'image et le lien
const handleSubmit = async () => {
  if (!imageUrl.value || !linkUrl.value) {
    alert("Veuillez remplir les champs Image et Lien");
    return;
  }

  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/carrousel/image', {
      image: imageUrl.value,
      href: linkUrl.value,
    });

    console.log('Image et lien ajoutés avec succès:', response.data);
    alert('Image et lien ajoutés avec succès');
    
    imageUrl.value = '';
    linkUrl.value = '';

    fetchCarouselData(); // Rafraîchir la liste des liens après ajout
  } catch (error) {
    console.error('Erreur lors de l\'ajout de l\'image et du lien:', error);
    alert('Une erreur est survenue lors de l\'ajout');
  }
};

// Fonction pour mettre à jour le délai de défilement
const handleSaveScrollDelay = async () => {
  try {
    await axios.put('https://api.meritemaritime-fnmm.com/carrousel/timer', {
      time: scrollDelay.value
    });
    alert("Délai de défilement mis à jour avec succès");
  } catch (error) {
    console.error("Erreur lors de la mise à jour du délai de défilement :", error);
    alert("Une erreur est survenue lors de la mise à jour");
  }
};

// Fonction pour supprimer un élément du carrousel
// Fonction pour supprimer un élément du carrousel
const handleDelete = async (id) => {
  try {
    // Envoi de la requête DELETE avec l'ID dans le body
    await axios.delete('https://api.meritemaritime-fnmm.com/carrousel/image', {
      data: { id }  // Envoi de l'id dans le body
    });
    console.log('Image supprimée avec succès');
    fetchCarouselData(); // Rafraîchir la liste des images après suppression
  } catch (error) {
    console.error("Erreur lors de la suppression de l'image :", error);
    alert("Une erreur est survenue lors de la suppression");
  }
};


// Appel à la fonction lors du montage du composant
onMounted(() => {
  fetchCarouselData();
  fetchScrollDelay(); // Récupérer le délai de défilement au chargement de la page
});

// Références pour l'URL de l'image et l'URL du lien
const imageUrl = ref('');
const linkUrl = ref('');
</script>

<template>
  <h2 class="text-2xl font-semibold text-gray-200">Carrousel</h2>
  <p class="mt-1 text-sm/6 text-gray-300">Importez vos images pour les intégrer facilement à votre carrousel sur la page d'accueil.</p>
  <div class="border-gray-700 border bg-gray-900 p-6 shadow rounded-xl mt-6">
    <div class="col-span-full">
      <label for="image" class="block text-sm/6 font-medium text-white">URL Image</label>
      <div class="mt-2">
        <input v-model="imageUrl" type="text" id="image" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <label for="link" class="block text-sm/6 font-medium text-white mt-4">URL Lien</label>
      <div class="mt-2">
        <input v-model="linkUrl" type="text" id="link" placeholder="-" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div class="w-full flex justify-end mt-4">
        <button @click="handleSubmit" type="button" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter</button>
      </div>
    </div>
    <div class="col-span-full mt-6">
      <label for="title" class="block text-sm/6 font-medium text-white">Délai de défilement</label>
      <div class="mt-2">
        <input v-model="scrollDelay" type="number" name="title" id="title" min="0" class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" />
      </div>
      <div class="w-full flex justify-end mt-4">
        <button @click="handleSaveScrollDelay" type="button" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Enregistrer</button>
      </div>
    </div>
  </div>

  <div class="mt-16 border-t border-white/10 pt-28">
    <dl class="divide-y divide-white/10">
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm/6 font-medium text-white">Tous les liens</dt>
        <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
            <!-- Affichage dynamique des liens récupérés depuis l'API -->
            <li v-for="(link, index) in carouselLinks" :key="link.id" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
              <div class="flex w-0 flex-1 items-center">
                <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                  <span class="truncate font-medium">{{ link.image }}</span>
                </div>
              </div>
              <div class="ml-4 shrink-0 items-center flex gap-4">
                <a :href="link.href" class="font-medium text-green-400 hover:text-green-300">Accéder</a>
                <button @click="handleDelete(link.id)">
                  <TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" />
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
  <h1 class="text-gray-200 text-xl">Visualiser</h1>
  <p class="text-gray-300">Rendu en temps réel sur la page vitrine.</p>
</template>
