<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">{{ section.nom }}</h2>
        <p class="mt-6 text-lg/8 text-gray-200">{{ section.description }}</p>
        <a v-if="section.link" :href="section.link" class="text-gray-200 hover:text-violet-600 flex px-4 py-2 rounded-xl w-52 justify-center items-center gap-2 mt-6 border ">
          Accéder au site
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-arrow-out-up-right"><path d="M21 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h6"/><path d="m21 3-9 9"/><path d="M15 3h6v6"/></svg>
        </a>
      </div>
    </div>
  </div>

  <!-- Liste des membres -->
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <ul role="list" class="mx-auto my-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-5">
        <li v-for="person in people" :key="person.id">
          <img class="mx-auto size-32 object-cover rounded-full" :src="person.img" alt="" />
          <h3 class="mt-6 text-base/7 font-semibold tracking-tight text-gray-900">{{ person.nom }} {{ person.prenom }}</h3>
          <p class="text-sm/6 text-gray-600">{{ person.role }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const section = ref({
  nom: '',
  description: '',
  link: null
});

const people = ref([]); // Variable réactive pour les membres

const idSection = window.location.pathname.split('/').pop(); // Récupère l'id_section depuis l'URL

// Fonction pour récupérer les informations de la section via l'API
const fetchSectionData = async () => {
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/sections/showlist`);
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des sections');
    }
    const data = await response.json();

    // Recherche la section correspondant à l'id_section
    const sectionData = data.find(item => item.id === parseInt(idSection));
    if (sectionData) {
      section.value = sectionData; // Met à jour les données de la section
    } else {
      console.error('Section non trouvée');
    }
  } catch (error) {
    console.error('Erreur de l\'API:', error);
  }
};

// Fonction pour récupérer les membres via l'API
const fetchMembers = async () => {
  try {
    const response = await fetch(`https://api.meritemaritime-fnmm.com/sections/showmember/${idSection}`);
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des membres');
    }
    const data = await response.json();
    people.value = data; // Met à jour la variable avec les données récupérées
  } catch (error) {
    console.error('Erreur de l\'API:', error);
  }
};

// Charge les données de la section et les membres dès que le composant est monté
onMounted(() => {
  fetchSectionData();
  fetchMembers();
});
</script>
