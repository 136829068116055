<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Content from "@/components/Histoire/Content.vue";

export default {
  name: "HistoirePage",
  components: {Content, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Content></Content>
  <AppFooter></AppFooter>
</template>

<style scoped>

</style>