<script setup>
</script>

<template>
  <div class="p-8 max-w-7xl mx-auto text-gray-900">
    <h1 class="text-3xl font-bold mb-6">Politique de Sécurité du Site FNMM-MH</h1>
    <p class="text-sm text-gray-500 mb-6">Dernière mise à jour : 17/12/2024</p>

    <section>
      <p class="mb-4">
        La FNMM-MH (Fédération Nationale du Mérite Maritime et de la Médaille d’Honneur des marins) s'engage à protéger la sécurité des données et des utilisateurs de son site.
        Cette politique définit les mesures techniques et organisationnelles mises en place pour prévenir les risques liés à l’utilisation du site, notamment en matière de confidentialité, d’intégrité et de disponibilité des informations.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">1. Objectifs de la politique de sécurité</h2>
      <ul class="list-disc ml-6">
        <li>Garantir la confidentialité des données personnelles des utilisateurs.</li>
        <li>Protéger le site et ses systèmes contre les accès non autorisés, les cyberattaques et les failles de sécurité.</li>
        <li>Assurer la disponibilité continue des services en ligne proposés par FNMM-MH.</li>
        <li>Prévenir et atténuer les risques d’incidents affectant les utilisateurs et les données.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">2. Mesures techniques de sécurité</h2>
      <ul class="list-disc ml-6">
        <li><strong>Chiffrement des données :</strong> Toutes les données sensibles échangées via le site sont protégées par un chiffrement SSL/TLS.</li>
        <li><strong>Mises à jour et correctifs :</strong> Le site et ses systèmes sont régulièrement mis à jour pour intégrer les derniers correctifs de sécurité.</li>
        <li><strong>Protection contre les cyberattaques :</strong> Mise en place de pares-feux, de systèmes de détection et de prévention des intrusions (IDS/IPS) pour surveiller et bloquer les tentatives malveillantes.</li>
        <li><strong>Authentification renforcée :</strong> Utilisation de mots de passe sécurisés et, si nécessaire, d’une authentification à deux facteurs (2FA).</li>
        <li><strong>Sauvegardes régulières :</strong> Les données du site sont sauvegardées périodiquement pour garantir leur récupération en cas de panne ou d’attaque.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">3. Protection des données personnelles</h2>
      <p>
        La FNMM-MH respecte les exigences du RGPD (Règlement Général sur la Protection des Données) et s’engage à collecter, traiter et conserver les données personnelles des utilisateurs de manière sécurisée.
      </p>
      <p>
        Les utilisateurs ont le droit de consulter, modifier ou supprimer leurs données en contactant le service dédié (voir section 7).
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">4. Responsabilités des utilisateurs</h2>
      <p>
        Les utilisateurs sont invités à adopter des pratiques sécurisées, notamment :
      </p>
      <ul class="list-disc ml-6">
        <li>Choisir des mots de passe forts et uniques pour leur compte.</li>
        <li>Ne pas partager leurs identifiants avec des tiers.</li>
        <li>Signaler toute activité suspecte ou tentative d’hameçonnage (phishing) aux administrateurs du site.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">5. Gestion des incidents de sécurité</h2>
      <p>
        En cas de faille de sécurité ou d'incident :
      </p>
      <ul class="list-disc ml-6">
        <li>FNMM-MH mettra immédiatement en œuvre les procédures nécessaires pour limiter les impacts (ex. : isolement des systèmes affectés, notification des parties concernées).</li>
        <li>Les utilisateurs impactés seront informés dans les meilleurs délais, conformément aux obligations légales.</li>
        <li>Une analyse approfondie de l’incident sera réalisée pour éviter qu'il ne se reproduise.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">6. Audits et améliorations continues</h2>
      <p>
        FNMM-MH effectue régulièrement des audits de sécurité pour identifier et corriger les vulnérabilités potentielles.
        La politique de sécurité est révisée périodiquement pour s'adapter aux évolutions des menaces et des technologies.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">7. Contact et signalement</h2>
      <p>
        Pour toute question, suggestion ou signalement concernant la sécurité du site, veuillez contacter :
      </p>
      <address class="italic">
        FNMM-MH - Département de la sécurité numérique<br />
        <a href="mailto:communication@meritemaritime-fnmm.com">communication@meritemaritime-fnmm.com</a>
      </address>
    </section>

    <section>
      <h2 class="text-xl font-semibold mb-4">8. Acceptation et mise à jour de la politique</h2>
      <p>
        En utilisant le site FNMM-MH, les utilisateurs acceptent cette politique de sécurité. FNMM-MH se réserve le droit de la modifier à tout moment, et les changements seront communiqués via le site.
      </p>
    </section>
  </div>
</template>

<style scoped>
</style>
