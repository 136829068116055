<script setup>
import { ref, onMounted } from 'vue'
import { PaperClipIcon } from '@heroicons/vue/20/solid'

const links = ref([])
const documents = ref([])

const fetchPdfFiles = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/pdf')
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des fichiers PDF')
    }
    const data = await response.json()

    documents.value = data.map(item => ({
      name: item.url_image.split('/').pop(),
      size: 'Taille inconnue', // Si la taille est disponible, remplacez-la ici
      url: item.url_image,
    }))
  } catch (error) {
    console.error(error)
  }
}

const fetchUrlLinks = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/url')
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des liens URL')
    }
    const data = await response.json()

    links.value = data.map(item => ({
      name: item.url_lien.split('/').pop(),
      url: item.url_lien,
    }))
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  fetchPdfFiles()
  fetchUrlLinks()
})
</script>

<template>
  <div>
    <div class="px-4 sm:px-0">
      <h3 class="text-base/7 font-semibold text-white">Mes documents</h3>
      <p class="mt-1 max-w-2xl text-sm/6 text-gray-400">Accédez à l'ensemble des documents et des liens.</p>
    </div>
    <div class="mt-6 border-t border-white/10">
      <!-- Section Liens -->
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm/6 font-medium text-white">Liens</dt>
        <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
            <li v-for="(link, index) in links" :key="index" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
              <div class="flex w-0 flex-1 items-center">
                <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                  <span class="truncate font-medium">{{ link.name }}</span>
                </div>
              </div>
              <div class="ml-4 shrink-0">
                <a :href="link.url" class="font-medium text-indigo-400 hover:text-indigo-300">Lien</a>
              </div>
            </li>
          </ul>
        </dd>
      </div>

      <!-- Section Documents -->
      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm/6 font-medium text-white">Documents</dt>
        <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
            <li v-for="(doc, index) in documents" :key="index" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
              <div class="flex w-0 flex-1 items-center">
                <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                <div class="ml-4 flex min-w-0 flex-1 gap-2">
                  <span class="truncate font-medium">{{ doc.name }}</span>
                  <span class="shrink-0 text-gray-400">{{ doc.size }}</span>
                </div>
              </div>
              <div class="ml-4 shrink-0">
                <a :href="doc.url" class="font-medium text-indigo-400 hover:text-indigo-300">Télécharger</a>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>
  </div>
</template>
