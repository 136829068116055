<script setup>
</script>

<template>
  <div class="p-8 mx-auto max-w-7xl text-gray-900">
    <h1 class="text-3xl font-bold mb-6">Politique de Cookies</h1>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">1. Introduction</h2>
      <p>
        Cette politique de cookies explique comment la FNMM-MH utilise des cookies et technologies similaires sur notre site web.
        En visitant notre site, vous acceptez l'utilisation des cookies conformément à cette politique.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">2. Qu'est-ce qu'un cookie ?</h2>
      <p>
        Un cookie est un petit fichier texte stocké sur votre appareil (ordinateur, smartphone, etc.) par un site web.
        Il contient des informations sur votre navigation et permet d'améliorer votre expérience en ligne.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">3. Les types de cookies que nous utilisons</h2>
      <ul class="list-disc ml-6">
        <li><strong>Cookies essentiels :</strong> nécessaires pour le bon fonctionnement du site.</li>
        <li><strong>Cookies de performance :</strong> recueillent des informations sur l'utilisation du site.</li>
        <li><strong>Cookies de fonctionnalité :</strong> mémorisent vos préférences.</li>
        <li><strong>Cookies publicitaires :</strong> utilisés pour afficher des publicités adaptées à vos intérêts.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">4. Cookies tiers</h2>
      <p>
        Nous pouvons autoriser des tiers (comme des services d’analyse ou partenaires publicitaires) à placer des cookies sur votre appareil.
        Ces cookies sont soumis aux politiques de confidentialité de ces tiers.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">5. Comment gérer les cookies ?</h2>
      <p>
        Vous pouvez gérer ou désactiver les cookies via les paramètres de votre navigateur. Voici les liens vers les instructions des navigateurs courants :
      </p>
      <ul class="list-disc ml-6">
        <li><a href="#">Google Chrome</a></li>
        <li><a href="#">Mozilla Firefox</a></li>
        <li><a href="#">Safari</a></li>
        <li><a href="#">Microsoft Edge</a></li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">6. Durée de conservation des cookies</h2>
      <p>
        La durée de conservation varie selon leur type. Certains sont supprimés à la fermeture de votre navigateur,
        tandis que d'autres restent sur votre appareil jusqu'à leur expiration ou suppression.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">7. Mises à jour de la politique de cookies</h2>
      <p>
        Nous nous réservons le droit de modifier cette politique à tout moment. Consultez cette page régulièrement pour rester informé des changements.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">8. Contact</h2>
      <p>Pour toute question concernant cette politique de cookies, vous pouvez nous contacter :</p>
      <address class="italic">
        FNMM-MH - 2, rue Henri Barbusse - 13001 Marseille
      </address>
    </section>
  </div>
</template>

<style scoped>
/* Ajoutez des styles spécifiques si nécessaire */
</style>