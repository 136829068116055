<template>
  <div class="bg-neutral-50 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
        Actualités
      </h2>

      <!-- Premier article en vedette -->
      <div v-if="articles.length" class="mx-auto mb-16 max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-1">
        <a
          :href="'/nouvelles-de-la-federation/article/' + articles[0].id"
          class="flex flex-col items-start justify-between"
        >
          <div class="relative w-full">
            <img
              :src="articles[0].url_image"
              alt=""
              class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl mt-8">
            <div class="flex items-center gap-x-4 text-xs">
              <time :datetime="articles[0].date" class="text-gray-500">
                {{ articles[0].date || 'Date non disponible' }}
              </time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-2xl font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ articles[0].titre }}
                </span>
              </h3>
              <p class="mt-5 text-base text-gray-600">{{ articles[0].description }}</p>
            </div>
          </div>
        </a>
      </div>

      <!-- Liste des autres articles -->
      <div v-if="articles.length > 1" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <a
          v-for="article in articles.slice(1)"
          :key="article.id"
          :href="'/nouvelles-de-la-federation/article/' + article.id"
          class="flex flex-col items-start justify-between"
        >
          <div class="relative w-full">
            <img
              :src="article.url_image"
              alt=""
              class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            />
            <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div>
          <div class="max-w-xl">
            <div class="mt-8 flex items-center gap-x-4 text-xs">
              <time :datetime="article.date" class="text-gray-500">
                {{ article.date || 'Date non disponible' }}
              </time>
            </div>
            <div class="group relative">
              <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                <span>
                  <span class="absolute inset-0" />
                  {{ article.titre }}
                </span>
              </h3>
              <p class="mt-5 line-clamp-3 text-sm text-gray-600">{{ article.description }}</p>
            </div>
          </div>
        </a>
      </div>

      <div v-else class="text-center py-32">
        <p class="text-gray-500">Chargement des articles...</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

// Liste des articles
const articles = ref([]);

onMounted(async () => {
  try {
    // Appel API pour récupérer les articles
    const response = await axios.get('https://api.meritemaritime-fnmm.com/federation/article');
    articles.value = response.data; // Assurez-vous que l'API renvoie une liste d'articles
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
  }
});
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés si nécessaire */
</style>
