<script setup>
</script>

<template>
  <div class="p-8 max-w-7xl mx-auto text-gray-900">
    <h1 class="text-3xl font-bold mb-6">Politique de Confidentialité</h1>
    <p class="text-sm text-gray-500 mb-6">Dernière mise à jour : 20/12/2024</p>

    <section>
      <p class="mb-4">
        La présente Politique de Confidentialité décrit la manière dont « www.meritemaritime-fnmm.com » collecte, utilise, protège et partage les informations personnelles des utilisateurs de notre site web.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">1. Collecte des informations personnelles</h2>
      <p class="mb-4">
        Nous collectons des informations personnelles lorsque vous utilisez notre site, que vous remplissiez un formulaire, que vous vous inscriviez à notre newsletter ou que vous interagissiez avec notre contenu. Les types d’informations collectées peuvent inclure :
      </p>
      <ul class="list-disc ml-6">
        <li>Nom</li>
        <li>Adresse e-mail</li>
        <li>Adresse IP</li>
        <li>Informations de navigation (par exemple, pages visitées, durée de la visite)</li>
        <li>Informations de paiement (le cas échéant, pour les achats)</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">2. Utilisation des informations</h2>
      <ul class="list-disc ml-6">
        <li>Fournir et améliorer nos services</li>
        <li>Gérer vos demandes ou comptes</li>
        <li>Personnaliser votre expérience sur notre site</li>
        <li>Vous envoyer des informations, promotions ou mises à jour (si vous y avez consenti)</li>
        <li>Analyser l’utilisation du site et améliorer son contenu</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">3. Partage des informations</h2>
      <p class="mb-4">
        Nous ne vendons pas, ne louons pas et ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
      </p>
      <ul class="list-disc ml-6">
        <li><strong>Prestataires de services tiers :</strong> Nous pouvons partager vos informations avec des prestataires qui nous aident à gérer des fonctions spécifiques (hébergement web).</li>
        <li><strong>Conformité légale :</strong> Nous pouvons divulguer vos informations si la loi l’exige.</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">4. Cookies et technologies similaires</h2>
      <p class="mb-4">
        Nous utilisons des cookies pour améliorer l’expérience des utilisateurs. Vous pouvez configurer votre navigateur pour refuser les cookies, mais certaines fonctionnalités du site pourraient être affectées.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">5. Sécurité des données</h2>
      <p class="mb-4">
        Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles. Cependant, aucune méthode de transmission ou de stockage n’est totalement sûre.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">6. Conservation des données</h2>
      <p>
        Nous conservons vos informations aussi longtemps que nécessaire pour répondre à nos obligations légales, résoudre les litiges et faire respecter nos accords.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">7. Vos droits</h2>
      <p>
        Vous disposez des droits suivants : accès, rectification, effacement, portabilité et opposition au traitement de vos données. Contactez-nous pour les exercer.
      </p>
    </section>

    <section class="mb-8">
      <h2 class="text-xl font-semibold mb-4">8. Modification de cette politique</h2>
      <p>
        Nous nous réservons le droit de modifier cette politique. Les changements seront publiés ici. Nous vous encourageons à consulter cette page régulièrement.
      </p>
    </section>

    <section>
      <h2 class="text-xl font-semibold mb-4">9. Contact</h2>
      <address class="italic">
        FNMM-MH<br />
        Immeuble CMCI – 2, rue Henri Barbusse – 13001 Marseille<br />
        <a href="mailto:president@meritemaritime-fnmm.com">president@meritemaritime-fnmm.com</a>
      </address>
    </section>
  </div>
</template>

<style scoped>
</style>
