<template>
  <div>
    <!-- Article dynamique -->
    <div v-if="article" class="bg-white px-6 py-32 lg:px-8">
      <div class="mx-auto max-w-3xl text-base/7 text-gray-700">
        <p class="text-base/7 font-semibold text-indigo-600">Article n°{{ article.id }}</p>
        <h1 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          {{ article.titre }}
        </h1>
        <figure class="mt-16">
          <img
            class="aspect-video rounded-xl bg-gray-50 object-cover"
            :src="article.url_image"
            alt="Image de l'article"
          />
        </figure>
        <p class="mt-6 text-xl/8">{{ article.description }}</p>
      </div>
    </div>
    <div v-else class="text-center py-32">
      <p class="text-gray-500">Chargement de l'article...</p>
    </div>

    <!-- Autres articles -->
    <div v-if="articles.length" class="bg-neutral-50 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="max-w-2xl mb-20 text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Découvrez d'autres articles
        </h2>
        <div
          class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          <a
            v-for="post in articles"
            :key="post.id"
            :href="'/nouvelles-de-la-federation/article/' + post.id"
            class="flex flex-col items-start justify-between"
          >
            <div class="relative w-full">
              <img
                :src="post.url_image"
                alt=""
                class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
              />
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div class="max-w-xl">
              <div class="mt-8 flex items-center gap-x-4 text-xs">
                <time :datetime="post.date" class="text-gray-500">
                  {{ post.date || 'Date non disponible' }}
                </time>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg font-semibold text-gray-900 group-hover:text-gray-600">
                  <span>
                    <span class="absolute inset-0" />
                    {{ post.titre }}
                  </span>
                </h3>
                <p class="mt-5 line-clamp-3 text-sm text-gray-600">{{ post.description }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-else class="text-center py-32">
      <p class="text-gray-500">Chargement des articles...</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';

// Récupération de l'article dynamique
const route = useRoute();
const article = ref(null);
const articles = ref([]);

onMounted(async () => {
  const articleId = route.params.id; // Récupère l'ID de l'article à partir de l'URL
  try {
    // API pour récupérer l'article spécifique
    const articleResponse = await axios.get(`https://api.meritemaritime-fnmm.com/federation/onearticle/${articleId}`);
    article.value = articleResponse.data;

    // API pour récupérer tous les articles
    const articlesResponse = await axios.get('https://api.meritemaritime-fnmm.com/federation/article');
    articles.value = articlesResponse.data; // Assurez-vous que l'API renvoie une liste d'articles
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
  }
});
</script>

<style scoped>
/* Vous pouvez ajouter des styles spécifiques ici si nécessaire */
</style>
