<script>
  import AppHeader from "@/components/Essentials/AppHeader.vue";
  import AppFooter from "@/components/Essentials/AppFooter.vue";
  import ContactForm from "@/components/Contact/ContactForm.vue";
  import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

  export default {
    name : 'Contact',
    components : {
      Fastnav,
      ContactForm,
      AppFooter,
      AppHeader
    }
  }
</script>

<template>
  <AppHeader></AppHeader>
  <ContactForm></ContactForm>
  <Fastnav></Fastnav>
  <AppFooter></AppFooter>
</template>
