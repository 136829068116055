<script setup>
import { ref, watch } from 'vue';
import axios from 'axios'; // Pour effectuer des appels API

// Données réactives
const people = ref([]);
const searchQuery = ref('');
const isLoading = ref(false);
const errorMessage = ref(null);

// Débounce Timer
let debounceTimeout = null;

// Fonction pour effectuer un appel POST à l'API
const searchPeople = async () => {
  if (!searchQuery.value.trim()) {
    people.value = [];
    errorMessage.value = null;
    return;
  }

  isLoading.value = true;
  errorMessage.value = null;

  try {
    const response = await axios.post('https://api.meritemaritime-fnmm.com/medailler/search', {
      type: 'mm', // Type de recherche
      name: searchQuery.value,
    });

    // Mettre à jour les résultats si la requête réussit
    if (response.data && response.data.length > 0) {
      people.value = response.data.map((person) => ({
        name: person.NOM,
        Chevalier: person.Chevalier || '-',
        Officier: person.Officier || '-',
        Commandeur: person.Commandeur || '-',
        Autres: person.Autres || '-',
      }));
    } else {
      people.value = [];
      errorMessage.value = 'Aucun résultat trouvé pour cette recherche.';
    }
  } catch (error) {
    console.error('Erreur lors de la recherche:', error);
    errorMessage.value = 'Une erreur est survenue lors de la recherche.';
  } finally {
    isLoading.value = false;
  }
};

// Surveiller `searchQuery` et appliquer un debounce
watch(searchQuery, (newValue) => {
  clearTimeout(debounceTimeout); // Réinitialiser le timer précédent
  debounceTimeout = setTimeout(() => {
    searchPeople(); // Lancer la recherche après le délai
  }, 500); // Délai de 500ms
});
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
          LES DÉCORÉS MÉRITE MARITIME
        </h2>
        <p class="mt-6 text-lg/8 text-gray-200">
          La liste des personnes décorées et médaillées est incomplète; cependant, elle sera mise à jour régulièrement afin de refléter les informations les plus récentes.
        </p>
      </div>
    </div>
  </div>

  <div class="bg-white pt-16 pb-24 md:pb-32 lg:pb-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 px-6 lg:px-8 xl:grid-cols-1 mb-6">
      <label for="search" class="text-white px-4 py-2 bg-[#072653] rounded-tr-md rounded-tl-md">
        Rechercher
      </label>
      <input
        v-model="searchQuery"
        type="text"
        name="search"
        id="search"
        placeholder="Exemple : BOURDIER Jean"
        class="block w-full rounded-bl-md rounded-br-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
      />
    </div>

    <div class="mx-auto max-w-7xl px-6">
      <div v-if="isLoading" class="text-center text-gray-500">Recherche en cours...</div>
      <div v-else-if="errorMessage" class="text-center text-red-600">{{ errorMessage }}</div>

      <ul
        v-if="people.length > 0"
        role="list"
        class="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 mx-auto"
      >
        <li
          v-for="(person, index) in people"
          :key="index"
          class="border p-2 rounded shadow bg-neutral-50"
        >
          <h3 class="text-lg/8 font-semibold text-gray-900">{{ person.name }}</h3>
          <div class="grid lg:grid-cols-4 grid-cols-3">
            <div class="flex flex-col">
              <h4>Chevalier</h4>
              <p class="text-base/7 text-gray-600">{{ person.Chevalier }}</p>
            </div>
            <div class="flex flex-col">
              <h4>Officier</h4>
              <p class="text-base/7 text-gray-600">{{ person.Officier }}</p>
            </div>
            <div class="flex flex-col">
              <h4>Commandeur</h4>
              <p class="text-base/7 text-gray-600">{{ person.Commandeur }}</p>
            </div>
            <div class="flex flex-col">
              <h4>Autres</h4>
              <p class="text-base/7 text-gray-600">{{ person.Autres }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
