<template>
  <div class="bg-white py-24 md:py-32 lg:py-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-20 px-6 lg:px-8 xl:grid-cols-3">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Présidents</h2>
      </div>
      <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="president in presidents" :key="president.nom">
          <h3 class="mt-6 text-lg/8 font-semibold text-gray-900">{{ president.nom }}</h3>
          <p class="text-base/7 text-gray-600">{{ president.role }}</p>
          <p class="mt-4 text-base/7 text-gray-600">{{ president.description }}</p>
        </li>
      </ul>
    </div>
  </div>
  <div class="bg-neutral-50 pt-16 pb-24 md:pb-32 lg:pb-40">
    <div class="mx-auto grid max-w-7xl grid-cols-1 gap-20 px-6 lg:px-8 xl:grid-cols-3">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Membres</h2>
      </div>
      <ul role="list" class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
        <li v-for="person in members" :key="person.nom">
          <h3 class="mt-6 text-lg/8 font-semibold text-gray-900">{{ person.nom }}</h3>
          <p class="text-base/7 text-gray-600">{{ person.role }}</p>
          <p class="mt-4 text-base/7 text-gray-600">{{ person.description }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const presidents = ref([])
const members = ref([])

const fetchData = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/conseil/list')
    const data = await response.json()

    // Populate presidents and members based on rank
    presidents.value = data.filter(item => item.rank === 1 || item.rank === 2)
    members.value = data.filter(item => item.rank > 2)
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

onMounted(() => {
  fetchData()
})
</script>
