<template>
  <div class="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6 z-20">
    <div class="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
      <p class="text-sm/6 text-gray-900">
        Ce site utilise des cookies pour améliorer votre expérience de navigation et mettre en valeur les actions et les informations de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins. L'acceptation des cookies est optionnelle mais recommandée pour profiter pleinement de nos services. Consultez notre <a href="https://www.meritemaritime-fnmm.com/" class="font-semibold text-green-600">politique de cookies</a>.
      </p>
      <div class="mt-4 flex items-center gap-x-5">
        <button type="button" class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Tout accepter</button>
        <button type="button" class="text-sm/6 font-semibold text-gray-900">Tout refuser</button>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>