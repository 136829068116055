<template>
  <div class="mb-10">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div 
        v-for="item in stats" 
        :key="item.name" 
        class="overflow-hidden rounded-lg bg-gray-900 px-4 py-5 shadow sm:p-6 border-gray-700 border">
        <dt class="truncate text-sm font-medium text-gray-300">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-100">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const stats = ref([
  { name: 'Mails reçus au total', stat: '0' },
  { name: 'Mails non lus', stat: '0' },
  { name: 'Mails lus', stat: '0' },
]);

const fetchStats = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/messagerie/stats');
    const data = response.data;

    // Mise à jour des valeurs dynamiquement
    stats.value = [
      { name: 'Mails reçus au total', stat: data.total },
      { name: 'Mails non lus', stat: data.non_lu },
      { name: 'Mails lus', stat: data.lu },
    ];
  } catch (error) {
    console.error('Erreur lors de la récupération des statistiques:', error);
  }
};

// Appel de la fonction au montage du composant
onMounted(() => {
  fetchStats();
});
</script>
