<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Members from "@/components/Sections/Vue/Members.vue";
import NewsSection from "@/components/Sections/Vue/NewsSection.vue";

export default {
  name: "SectionsVue",
  components: {NewsSection, Members, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Members></Members>
  <NewsSection></NewsSection>
  <AppFooter></AppFooter>
</template>
