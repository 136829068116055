<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl pt-0 sm:px-6 sm:py-12 lg:px-8">
      <div class="relative overflow-hidden rounded-none sm:rounded-lg lg:rounded-lg h-[500px] sm:h-[500px] shadow">
        <div class="flex transition-transform duration-700 ease-in-out" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
          <!-- Affichage des images récupérées dynamiquement -->
          <a v-for="(image, index) in images" :key="index" :href="image.href" class="w-[100%] flex-shrink-0 h-full">
            <img :src="image.image" alt="" class="w-full min-h-[500px] h-full object-cover object-center" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import axios from 'axios';

// Variables réactives pour stocker les images et le délai de défilement
const images = ref([]);
const currentSlide = ref(0);
const scrollDelay = ref(5000); // Valeur par défaut de délai (en millisecondes)
let intervalId;

// Fonction pour démarrer le carrousel avec un délai dynamique
const startCarousel = () => {
  intervalId = setInterval(() => {
    currentSlide.value = (currentSlide.value + 1) % images.value.length;
  }, scrollDelay.value);
};

// Récupérer les images depuis l'API du carrousel
const fetchImages = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/carrousel/image');
    images.value = [...images.value, ...response.data]; // Ajouter les images du carrousel après l'article
  } catch (error) {
    console.error("Erreur lors de la récupération des images du carrousel:", error);
  }
};

// Récupérer le premier article depuis l'API de la fédération
const fetchFederationArticle = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/federation/article');
    const firstArticle = response.data[0]; // Prendre le premier article
    if (firstArticle) {
      // Ajouter l'article en premier dans le tableau des images
      images.value = [{
        image: firstArticle.url_image,
        href: `https://www.meritemaritime-fnmm.com/nouvelles-de-la-federation/article/${firstArticle.id}`,
      }, ...images.value];
    }
  } catch (error) {
    console.error("Erreur lors de la récupération de l'article de la fédération:", error);
  }
};

// Récupérer le délai de défilement depuis l'API
const fetchScrollDelay = async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/carrousel/timer'); // Assurez-vous que l'API retourne le délai dans la clé 'time'
    scrollDelay.value = response.data.time || 5000; // Si aucune valeur n'est définie, utiliser la valeur par défaut de 5000ms
  } catch (error) {
    console.error("Erreur lors de la récupération du délai de défilement:", error);
  }
};

// Lancer la récupération des images, de l'article et du délai au montage du composant
onMounted(() => {
  fetchFederationArticle(); // Récupérer le premier article et l'ajouter en premier dans le carrousel
  fetchScrollDelay().then(() => {
    startCarousel(); // Démarrer le carrousel après avoir récupéré le délai
  });
  fetchImages(); // Récupérer les images du carrousel après l'article
});

// Nettoyer l'intervalle lors du démontage du composant
onUnmounted(() => {
  clearInterval(intervalId);
});
</script>
