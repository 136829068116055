<template>
  <div class="bg-gray-900 p-10 rounded-xl shadow mb-10 border-gray-700 border">
    <div class="px-4 sm:px-0">
      <h3 class="text-base/7 font-semibold text-white">Lecture</h3>
    </div>
    <div class="mt-6 border-t border-white/10">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Nom</dt>
          <dd class="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{{ currentMail.lastname || 'Aucune donnée' }}</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Objet</dt>
          <dd class="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{{ currentMail.object || 'Aucune donnée' }}</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Date</dt>
          <dd class="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{{ currentMail.date || 'Non disponible' }}</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Email</dt>
          <dd class="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{{ currentMail.email || 'Aucune donnée' }}</dd>
        </div>
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Message</dt>
          <dd class="mt-1 text-sm/6 text-gray-400 sm:col-span-2 sm:mt-0">{{ currentMail.message || 'Aucune donnée' }}</dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

// Référence pour stocker le mail actuel
const currentMail = ref({});

// Fonction pour récupérer le mail du localStorage
function getMailFromLocalStorage() {
  const storedMail = localStorage.getItem('ViewMail');
  if (storedMail) {
    return JSON.parse(storedMail);
  }
  return {}; // Retourner un objet vide si aucune donnée n'est trouvée
}

// Fonction pour mettre à jour l'état à partir de localStorage
function updateMail() {
  currentMail.value = getMailFromLocalStorage();
}

// Initialisation du mail à partir de localStorage
onMounted(() => {
  updateMail();

  // Vérification périodique toutes les 500ms
  const intervalId = setInterval(updateMail, 500);

  // Nettoyage lors du démontage du composant
  onBeforeUnmount(() => {
    clearInterval(intervalId);
  });
});
</script>
