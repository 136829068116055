<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">LES SECTIONS</h2>
        <p class="mt-6 text-lg/8 text-gray-200">La Fédération nationale du Mérite Maritime rassemble des délégations réparties sur tout le territoire, incluant des régions métropolitaines et ultramarines, afin de représenter et honorer les marins dans toutes ces zones.</p>
      </div>
    </div>
  </div>
  <div class="bg-neutral-50 py-12">
    <div class="mx-auto max-w-7xl px-6">
      <div class="mt-8 lg:px-8 divide-y divide-gray-200 bg-white overflow-hidden rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div v-for="(action, actionIdx) in actions" :key="action.id" :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500']">
          <div class="flex items-center justify-between">
            <h3 class="text-base font-semibold text-gray-900">
              <a :href="`/sections/${action.id}`" class="focus:outline-none">
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.nom }}
              </a>
            </h3>
            <span class="pointer-events-none text-gray-300 group-hover:text-gray-400" aria-hidden="true">
              <svg class="size-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const actions = ref([]);

// Fonction pour récupérer les sections depuis l'API
const fetchSections = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/sections/showlist');
    const data = await response.json();
    actions.value = data.map(section => ({
      id: section.id,
      nom: section.nom,
      description: section.description,
      href: `/sections/${section.id}`
    }));
  } catch (error) {
    console.error('Erreur lors de la récupération des sections:', error);
  }
};

// Appel à l'API lors du montage du composant
onMounted(() => {
  fetchSections();
});
</script>
