<template>
  <div class="z-20 w-full fixed top-0 left-0 bg-white" >
    <div class="bg-gray-900 z-20 relative">
      <div class="mx-auto flex h-10 max-w-7xl items-center justify-end px-4 sm:px-6 lg:px-8">
        <div class="flex items-center space-x-6">
          <a href="/page-de-connexion" class="text-sm font-medium text-white hover:text-gray-100">Se connecter</a>
        </div>
      </div>
    </div>
    <nav :class="['mx-auto relative z-10 justify-center items-center gap-6 max-w-7xl lg:px-8 transition-all duration-300 hidden lg:flex ',isVisible ? 'bottom-[130px] h-[0px] p-0' : 'bottom-[0px] h-[110px] lg:px-8']" aria-label="Global">
      <div class="flex">
        <a href="#" class="-m-1.5 p-1.5">
          <img class="h-20 min-w-20 w-auto rounded-full" src="@/assets/Logos/logo.png" />
        </a>
      </div>
      <div class="flex flex-col">
        <h1 class="text-2xl text-gray-900">
          FÉDÉRATION NATIONALE DU MÉRITE MARITIME ET DE LA MÉDAILLE D'HONNEUR DES MARINS
        </h1>
        <p class="font-semibold">
          Association reconnue d'Intérêt général - Art. 200-1-b et 238 bis-1-a du CGI
        </p>
      </div>
    </nav>



    <header class="relative isolate z-10 bg-white border-b">
      <nav class="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1">
          <a href="#" class="-m-1.5 p-1.5">
            <img class="max-h-12 w-auto max-w-80 h-12 flex" src="@/assets/Logos/gouv.png"/>
          </a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="size-6" aria-hidden="true" />
          </button>
        </div>

        <PopoverGroup class="hidden lg:flex lg:gap-x-12">
          <a href="/" class="text-sm/6 font-semibold text-gray-900">Accueil</a>

          <Popover>
            <PopoverButton class="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
              Présentation
              <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
              <PopoverPanel class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
                <div class="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  <div v-for="item in presentation" :key="item.name" class="group relative rounded-lg p-6 text-sm/6 hover:bg-gray-50">
                    <a :href="item.href" class="block font-semibold text-gray-900">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </a>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <Popover>
            <PopoverButton class="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
              Actualité FNMM
              <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
              <PopoverPanel class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
                <div class="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  <div v-for="item in actu" :key="item.name" class="group relative rounded-lg p-6 text-sm/6 hover:bg-gray-50">
                    <a :href="item.href" class="block font-semibold text-gray-900">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </a>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <a href="/congres-fnmm" class="text-sm/6 font-semibold text-gray-900">Congrès</a>
          <a href="/sections" class="text-sm/6 font-semibold text-gray-900">Sections</a>

          <Popover>
            <PopoverButton class="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
              Les décorés MM-MH
              <ChevronDownIcon class="size-5 flex-none text-gray-400" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
              <PopoverPanel class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">
                <div class="mx-auto grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  <div v-for="item in decores" :key="item.name" class="group relative rounded-lg p-6 text-sm/6 hover:bg-gray-50">
                    <a :href="item.href" class="block font-semibold text-gray-900">
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </a>
                    <p class="mt-1 text-gray-600">{{ item.description }}</p>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>

          <a href="/support" class="text-sm/6 font-semibold text-gray-900">Contact</a>

        </PopoverGroup>
      </nav>



      <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-10" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="#" class="-m-1.5 p-1.5">
              <img class="h-12 w-auto" src="@/assets/Logos/logo.png" alt="" />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="size-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a href="/" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Accueil</a>
                <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                  <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    Présentation
                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']" aria-hidden="true" />
                  </DisclosureButton>
                  <DisclosurePanel class="mt-2 space-y-2">
                    <DisclosureButton v-for="item in [...presentation]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                  <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    Actualité FNMM
                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']" aria-hidden="true" />
                  </DisclosureButton>
                  <DisclosurePanel class="mt-2 space-y-2">
                    <DisclosureButton v-for="item in [...actu]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                  </DisclosurePanel>
                </Disclosure>
                <a href="/congres-fnmm" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Congrès</a>
                <a href="/sections" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Sections</a>
                <Disclosure as="div" class="-mx-3" v-slot="{ open }">
                  <DisclosureButton class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                    Les décorés MM-MH
                    <ChevronDownIcon :class="[open ? 'rotate-180' : '', 'size-5 flex-none']" aria-hidden="true" />
                  </DisclosureButton>
                  <DisclosurePanel class="mt-2 space-y-2">
                    <DisclosureButton v-for="item in [...decores]" :key="item.name" as="a" :href="item.href" class="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">{{ item.name }}</DisclosureButton>
                  </DisclosurePanel>
                </Disclosure>
                <a href="/support" class="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Contact</a>

              </div>
              <div class="py-6">
                <a href="/page-de-connexion" class="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">Se connecter</a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const presentation = [
  {
    name: 'Histoire Mérite Maritime',
    description: 'Découvrez l’histoire et les origines du Mérite Maritime.',
    href: '/histoire-maritime',
  },
  {
    name: 'Conseil de l\'Ordre',
    description: 'Apprenez-en plus sur les membres et le rôle du Conseil de l\'Ordre.',
    href: '/conseil-de-l-ordre',
  },
  {
    name: 'Médaille d\'honneur',
    description: 'Informations sur la Médaille d\'honneur et ses distinctions.',
    href: '/medaille-honneur',
  },
  {
    name: 'Status Fédération',
    description: 'Consultez les statuts officiels de la Fédération.',
    href: '/statuts-federation',
  }
]
const actu = [
  {
    name: 'Nouvelles de la Fédération',
    description: 'Restez informé des dernières actualités et événements de la Fédération.',
    href: '/nouvelles-de-la-federation',
  },
  {
    name: 'Centre de Documation Maritime',
    description: 'Explorez notre base de ressources dédiée au patrimoine maritime.',
    href: '/centre-de-documentation-maritime',
  },
  {
    name: 'Prix Littéraires FNMM',
    description: 'Découvrez les récompenses littéraires décernées par la FNMM.',
    href: '/prix-litteraires',
  }
]
const decores = [
  {
    name: 'Décorations récentes et Croix 2024',
    description: 'Découvrez les dernières distinctions et Croix attribuées en 2024.',
    href: '/les-decores',
  },
  {
    name: 'Médaillés d\'Honneur depuis 1934',
    description: 'Parcourez l\'histoire des Médaillés d\'honneur depuis 1934.',
    href: '/les-decores',
  },
  {
    name: 'Décorés Mérite Maritime depuis 1930',
    description: 'Accédez à la liste des décorés du Mérite Maritime depuis 1930.',
    href: '/les-decores-mm',
  }
]
const mobileMenuOpen = ref(false)

const isVisible = ref(false)

const handleScroll = () => {
  if (window.scrollY > 10) {
    isVisible.value = true
  } else {
    isVisible.value = false
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>


