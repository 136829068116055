<script>
  import AppHeader from "@/components/Essentials/AppHeader.vue";
  import AppFooter from "@/components/Essentials/AppFooter.vue";
  import Banner from "@/components/Prix/Banner.vue";
  import PrixList from "@/components/Prix/PrixList.vue";
  import Fastnav from "@/components/Home/FastNav/Fastnav.vue";

  export default {
    name : 'Prix',
    components: {Fastnav, PrixList, Banner, AppFooter, AppHeader},

  }
</script>

<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <PrixList></PrixList>
  <Fastnav></Fastnav>
  <AppFooter></AppFooter>
</template>

<style scoped>

</style>