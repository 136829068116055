<template>
  <div class="isolate bg-white px-6 py-24 sm:py-32 lg:px-8 mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">Contact</h2>
      <p class="mt-2 text-lg/8 text-gray-600">Tout abus de la messagerie entraînera un bannissement immédiat et définitif du site. Merci de respecter les règles d'utilisation pour maintenir un environnement sûr et convivial pour tous.</p>
    </div>
    <form @submit.prevent="handleSubmit" class="mx-auto mt-16 max-w-xl sm:mt-20">
      <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div>
          <label for="first-name" class="block text-sm/6 font-semibold text-gray-900">Prénom*</label>
          <div class="mt-2.5">
            <input type="text" v-model="form.firstName" required name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
          </div>
        </div>
        <div>
          <label for="last-name" class="block text-sm/6 font-semibold text-gray-900">Nom*</label>
          <div class="mt-2.5">
            <input type="text" v-model="form.lastName" required name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="email" class="block text-sm/6 font-semibold text-gray-900">Email*</label>
          <div class="mt-2.5">
            <input type="email" v-model="form.email" required name="email" id="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="object" class="block text-sm/6 font-semibold text-gray-900">Objet*</label>
          <div class="mt-2.5">
            <input type="text" v-model="form.object" required name="object" id="object" autocomplete="object" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="message" class="block text-sm/6 font-semibold text-gray-900">Message*</label>
          <div class="mt-2.5">
            <textarea v-model="form.message" required name="message" id="message" rows="4" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
          </div>
        </div>
      </div>
      <div class="mt-10">
        <button type="submit" class="block w-full rounded-md bg-[#072653] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Envoyer</button>
      </div>
      <small class="mt-4 flex text-center">En cliquant sur le bouton "Envoyer", vous acceptez notre politique de confidentialité ainsi que les conditions d'utilisation de notre service de messagerie.</small>
    </form>
    <div v-if="responseMessage" class="mt-4 text-center text-lg">{{ responseMessage }}</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        object: '',
        message: '',
      },
      responseMessage: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/mailsend', this.form);
        this.responseMessage = 'Votre message a été envoyé avec succès!';
      } catch (error) {
        this.responseMessage = 'Une erreur s\'est produite lors de l\'envoi du message. Veuillez réessayer.';
      }
    },
  },
};
</script>
