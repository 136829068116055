<template>
  <div>
    <div class="px-4 sm:px-0">
      <h3 class="text-2xl font-semibold text-white mb-4">Documents destinés aux membres</h3>
    </div>
    <div class="bg-gray-900 p-6 rounded-xl border-gray-700 border">
      <div class="col-span-full">
        <label for="file-upload" class="block text-sm/6 font-medium text-white">Sélectionner un fichier</label>
        <label for="file-upload" class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-200/25 px-6 py-10 cursor-pointer">
          <div class="text-center">
            <svg class="mx-auto size-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4h24l8 8v32H6V4z" />
              <line x1="12" y1="20" x2="32" y2="20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="12" y1="26" x2="28" y2="26" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
              <line x1="12" y1="32" x2="24" y2="32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
            </svg>
            <div class="mt-4 flex text-sm/6 text-gray-600">
              <label for="file-upload" class="relative cursor-pointer rounded-md font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-green-500">
                <span>Choisir un fichier</span>
                <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="handleFileUpload" />
              </label>
              <p class="pl-1">ou glisser-déposer ici</p>
            </div>
            <p class="text-xs/5 text-gray-600">PDF, jusqu'à 50MB</p>
          </div>
        </label>
        <div class="w-full flex justify-end mt-4">
          <button type="button" @click="submitFile" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter</button>
        </div>
      </div>

      <!-- Input pour ajouter une URL -->
      <div class="col-span-full mt-6">
        <label for="url-input" class="block text-sm/6 font-medium text-white">Ajouter un lien URL</label>
        <div class="mt-2">
          <input
            type="text"
            id="url-input"
            v-model="newLinkUrl"
            placeholder="Entrez l'URL"
            class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6"
          />
        </div>
        <div class="w-full flex justify-end mt-4">
          <button type="button" @click="addUrlLink" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">Ajouter le lien</button>
        </div>
      </div>
    </div>

    <!-- Affichage des liens et fichiers téléchargés -->
    <div class="mt-16 border-t border-white/10 py-28">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Tous les liens</dt>
          <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
              <li v-for="link in links" :key="link.url_lien" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ link.url_lien }}</span>
                  </div>
                </div>
                <div class="ml-4 shrink-0 items-center flex gap-4">
                  <a :href="link.href" class="font-medium text-green-400 hover:text-green-300">Accéder</a>
                  <button @click="deleteUrlLink(link)">
                    <TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" />
                  </button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>

    <!-- Affichage des fichiers téléchargés (PDF) -->
    <div class="mt-16 border-t border-white/10 py-28">
      <dl class="divide-y divide-white/10">
        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm/6 font-medium text-white">Tous les fichiers</dt>
          <dd class="mt-2 text-sm text-white sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-white/10 rounded-md border border-white/20">
              <li v-for="member in members" :key="member.title" class="flex items-center justify-between py-4 pl-4 pr-5 text-sm/6">
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="size-5 shrink-0 text-gray-400" aria-hidden="true" />
                  <div class="ml-4 flex min-w-0 flex-1 gap-2">
                    <span class="truncate font-medium">{{ member.title }}</span>
                  </div>
                </div>
                <div class="ml-4 shrink-0 items-center flex gap-4">
                  <a :href="member.href" class="font-medium text-green-400 hover:text-green-300">Télécharger</a>
                  <button @click="deletePdfFile(member)">
                    <TrashIcon class="w-5 h-5 shrink-0 text-gray-400 hover:text-red-400" aria-hidden="true" />
                  </button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { PaperClipIcon, TrashIcon } from '@heroicons/vue/20/solid'

const links = ref([
  { title: 'https://www.meritemaritime-fnmm.com/exemple-de-lien', href: 'https://www.meritemaritime-fnmm.com/exemple-de-lien' },
])

const members = ref([])

const selectedFile = ref(null)
const newLinkUrl = ref('')

const fetchPdfFiles = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/pdf')
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des fichiers PDF')
    }
    const data = await response.json()

    members.value = data.map(item => ({
      title: item.url_image.split('/').pop(),
      href: item.url_image,
      id: item.id,
    }))
  } catch (error) {
    console.error(error)
  }
}

const fetchUrlLinks = async () => {
  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/url')
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des liens URL')
    }
    const data = await response.json()

    links.value = data.map(item => ({
      url_lien: item.url_lien,
      id: item.id,
    }))
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  fetchPdfFiles()
  fetchUrlLinks()
})

const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0]
}

const submitFile = async () => {
  if (!selectedFile.value) {
    alert('Veuillez sélectionner un fichier avant de soumettre.')
    return
  }

  const formData = new FormData()
  formData.append('file', selectedFile.value)

  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/pdf', {
      method: 'POST',
      body: formData,
    })

    if (!response.ok) {
      throw new Error('Erreur lors de l\'upload du fichier.')
    }

    const data = await response.json()

    members.value.push({
      title: data.fileName,
      href: data.urlimage,
      id: data.id,
    })

    alert('Fichier ajouté avec succès!')
  } catch (error) {
    console.error(error)
    alert('Une erreur est survenue lors de l\'ajout du fichier.')
  }
}

const addUrlLink = async () => {
  if (!newLinkUrl.value) {
    alert('Veuillez entrer une URL valide.')
    return
  }

  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/member/url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: newLinkUrl.value }),
    })

    if (!response.ok) {
      throw new Error('Erreur lors de l\'ajout du lien URL.')
    }

    const data = await response.json()

    links.value.push({
      title: newLinkUrl.value,
      href: newLinkUrl.value,
      id: data.id,
    })

    newLinkUrl.value = '' // Réinitialiser le champ URL
    alert('Lien ajouté avec succès!')
  } catch (error) {
    console.error(error)
    alert('Une erreur est survenue lors de l\'ajout du lien.')
  }
}

const deletePdfFile = async (member) => {
  const confirmDelete = confirm('Voulez-vous vraiment supprimer ce fichier ?')

  if (confirmDelete) {
    try {
      const response = await fetch(`https://api.meritemaritime-fnmm.com/member/pdf/${member.id}`, {
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du fichier.')
      }

      members.value = members.value.filter(m => m.id !== member.id)
      alert('Fichier supprimé avec succès!')
    } catch (error) {
      console.error(error)
      alert('Une erreur est survenue lors de la suppression du fichier.')
    }
  }
}

const deleteUrlLink = async (link) => {
  const confirmDelete = confirm('Voulez-vous vraiment supprimer ce lien ?')

  if (confirmDelete) {
    try {
      const response = await fetch(`https://api.meritemaritime-fnmm.com/member/url/${link.id}`, {
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du lien.')
      }

      links.value = links.value.filter(l => l.id !== link.id)
      alert('Lien supprimé avec succès!')
    } catch (error) {
      console.error(error)
      alert('Une erreur est survenue lors de la suppression du lien.')
    }
  }
}
</script>

