<template>
  <div class="bg-gray-900 rounded-xl shadow border-gray-700 border">
    <div class="mx-auto max-w-7xl">
      <div class="bg-gray-900 py-10 rounded-xl shadow">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-700">
                  <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">Nom</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Objet</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Email</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-white">Etat</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-800">
                  <tr v-for="person in people" :key="person.email">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">{{ person.lastname }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.object }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.email }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-300">{{ person.lu }}</td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a 
                        href="#" 
                        class="text-green-400 hover:text-green-300"
                        @click="viewDetails(person)"
                      >
                        Détails<span class="sr-only">, {{ person.lastname }}</span>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

// Déclaration de la variable réactive pour les données
const people = ref([]);

// Fonction pour charger les données depuis l'API
onMounted(async () => {
  try {
    const response = await axios.get('https://api.meritemaritime-fnmm.com/messagerie/mail');
    people.value = response.data; // Met à jour les données avec celles de l'API
  } catch (error) {
    console.error('Erreur lors de la récupération des données :', error);
  }
});

// Fonction pour gérer le clic sur "Détails"
async function viewDetails(person) {
  // Stocke les informations dans le localStorage
  localStorage.setItem('ViewMail', JSON.stringify(person));
  console.log(`Détails de ${person.lastname} enregistrés dans le localStorage`);

  // Envoie une requête PUT pour marquer l'email comme vu
  try {
    const response = await axios.put(`https://api.meritemaritime-fnmm.com/messagerie/mail/`, {
      id: person.id,
    });
    console.log(`Status de ${person.lastname} mis à jour`, response.data);
  } catch (error) {
    console.error(`Erreur lors de la mise à jour du status de ${person.lastname} :`, error);
  }
}
</script>
