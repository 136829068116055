<script>
import AppHeader from "@/components/Essentials/AppHeader.vue";
import AppFooter from "@/components/Essentials/AppFooter.vue";
import Banner from "@/components/Prix/Vue/Banner.vue";
import PrixList from "@/components/Prix/PrixList.vue";
import ImgList from "@/components/Prix/Vue/ImgList.vue";

export default {
  name: "PrixVue",
  components: {ImgList, PrixList, Banner, AppFooter, AppHeader}
}
</script>

<template>
  <AppHeader></AppHeader>
  <Banner></Banner>
  <ImgList></ImgList>
  <AppFooter></AppFooter>
</template>