<template>
  <form @submit.prevent="handleSubmit">
    <div class="space-y-12">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-2xl font-semibold text-gray-200">Galerie d'images</h2>
        <p class="mt-1 text-sm/6 text-gray-300">Importez vos images et obtenez une URL dédiée pour les intégrer facilement à votre site web.</p>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-gray-700 border p-6 bg-gray-900 rounded-xl shadow">
          <!-- Champ titre -->
          <div class="col-span-full">
            <label for="title" class="block text-sm/6 font-medium text-white">Titre de l'image</label>
            <div class="mt-2">
              <input 
                v-model="title" 
                type="text" 
                name="title" 
                id="title" 
                placeholder="-" 
                class="mt-2 px-4 py-4 block w-full rounded-md border-0 bg-white/5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm/6" 
              />
            </div>
          </div>

          <!-- Sélectionner une image -->
          <div class="col-span-full">
            <label for="file-upload" class="block text-sm/6 font-medium text-white">Sélectionner une image</label>
            <label for="file-upload" class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-200/25 px-6 py-10 cursor-pointer">
              <div class="text-center">
                <PhotoIcon class="mx-auto size-12 text-gray-300" aria-hidden="true" />
                <div class="mt-4 flex text-sm/6 text-gray-600">
                  <input 
                    id="file-upload" 
                    name="file-upload" 
                    type="file" 
                    class="sr-only" 
                    accept="image/*" 
                    @change="handleFileChange" 
                  />
                  <p class="pl-1">ou glisser-déposer ici</p>
                </div>
                <p class="text-xs/5 text-gray-600">PNG, JPG, jusqu'à 10MB</p>
              </div>
            </label>
          </div>

          <!-- Aperçu de l'image -->
          <div v-if="imagePreview" class="mt-4">
            <h3 class="text-sm/6 font-medium text-white">Aperçu de l'image</h3>
            <img :src="imagePreview" alt="Image prévisualisée" class="mt-2 rounded-lg border border-gray-700 w-full h-auto max-h-60 object-contain" />
          </div>
        </div>
      </div>
    </div>

    <!-- Bouton de soumission -->
    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Envoyer
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import { PhotoIcon } from '@heroicons/vue/24/solid'

// Déclarations des variables réactives
const title = ref('')
const file = ref(null)
const imagePreview = ref(null)

// Fonction pour gérer le changement de fichier
const handleFileChange = (event) => {
  const selectedFile = event.target.files[0]
  file.value = selectedFile

  if (selectedFile) {
    // Créer un aperçu de l'image
    imagePreview.value = URL.createObjectURL(selectedFile)
  }
}

// Fonction pour soumettre les données
const handleSubmit = async () => {
  if (!file.value) {
    alert('Veuillez sélectionner un fichier !')
    return
  }

  const formData = new FormData()
  formData.append('title', title.value)  // Ajout du title à FormData
  formData.append('image', file.value)  // Ajout de l'image à FormData

  try {
    const response = await fetch('https://api.meritemaritime-fnmm.com/galerie/image/', {
      method: 'POST',
      body: formData,
    })

    if (response.ok) {
      const data = await response.json()
      alert('Image envoyée avec succès !')
    } else {
      const errorData = await response.json()
      alert('Erreur: ' + errorData.message)
    }
  } catch (error) {
    console.error('Erreur lors de l\'envoi de l\'image:', error)
    alert('Une erreur est survenue.')
  }
}
</script>
