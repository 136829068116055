<template>
  <div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div v-for="item in stats" :key="item.name" class="overflow-hidden rounded-lg bg-[#111827] px-4 py-5 shadow sm:p-6 border-gray-700 border">
        <dt class="truncate text-sm font-medium text-gray-200">{{ item.name }}</dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-white">{{ item.stat }}</dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
const stats = [
  { name: 'Nombre total de membres', stat: '7 897' },
  { name: 'Visiteurs aujourd’hui', stat: '189' },
  { name: 'Visiteurs ce mois', stat: '2 903' },
]
</script>