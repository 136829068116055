<template>
  <div class="py-28 flex items-center w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">{{ congres.nom || 'Loading...' }}</h2>  <!-- Updated to dynamic title -->
      </div>
    </div>
  </div>
  <div class="bg-white">
    <main class="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="grid-cols-1 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 py-24">
          <div class="px-8 gap-4 flex flex-col">
          </div>
          <TabGroup as="div" class="flex sm:flex-col-reverse mt-24 lg:mt-0 justify-center"> <!-- Center carousel images -->
            <div class="mx-auto mt-6 w-full max-w-7xl sm:block lg:max-w-none">
              <TabList class="grid grid-cols-4 gap-6">
                <Tab v-for="image in congres.images" :key="image.id" class="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-indigo-500/50 focus:ring-offset-4" v-slot="{ selected }">
                  <span class="sr-only">{{ image.name }}</span>
                  <span class="absolute inset-0 overflow-hidden rounded-md">
                    <img :src="image.url" alt="" class="size-full object-cover" />
                  </span>
                  <span :class="[selected ? 'ring-indigo-500' : 'ring-transparent', 'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2']" aria-hidden="true" />
                </Tab>
              </TabList>
            </div>

            <TabPanels>
              <TabPanel v-for="image in congres.images" :key="image.id">
                <img :src="image.url" :alt="'Image ' + image.id" class="flex w-full object-cover sm:rounded-lg" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </main>
  </div>
</template><script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';  // to access the route and the ID
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue'

// Reactive data for the congres
const congres = ref({
  nom: '',  // Store the name of the conference
  item: [
    {
      description: 'Loading description...'
    }
  ],
  images: [],
});

// Get the route parameters
const route = useRoute();

// Fetch congres data based on the ID
const fetchCongresData = async () => {
  const idCongre = route.params.id; // Get the id from the route
  try {
    // Fetching the name of the conference from the new API
    const nameResponse = await fetch(`https://api.meritemaritime-fnmm.com/shownamecongre?id_congre=${idCongre}`);
    const nameData = await nameResponse.json();
    
    console.log("Fetched Name Data:", nameData); // Log the name data for debugging
    
    // Check if the name data is valid and clean it
    if (nameData && nameData[0] && nameData[0].nom) {
      congres.value.nom = nameData[0].nom.trim();  // Trim to remove \r\n or spaces
      console.log("Cleaned Name:", congres.value.nom); // Log cleaned name
    } else {
      congres.value.nom = 'Unknown Conference';  // Fallback if no name
    }

    // Fetching other congres details from the first API
    const response = await fetch(`https://api.meritemaritime-fnmm.com/showidcongre?id_congre=${idCongre}`);
    const data = await response.json();
    
    // Assume the response contains an array of images and a description
    congres.value.images = data.map((item) => ({
      id: item.id,
      url: item.url
    }));

    // Example description for now, you can update this based on the API's response.
    congres.value.item = [
      {
        description: "Detailed description of the congres event." // Replace with the actual API response description if available
      }
    ];
  } catch (error) {
    console.error('Error fetching congres data:', error);
    congres.value.nom = 'Unable to load conference name';  // Fallback in case of error
  }
};

// Fetch the data when the component is mounted
onMounted(() => {
  fetchCongresData();
});
</script>
