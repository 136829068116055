<script setup>
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/16/solid';
import Confidentialite from "@/components/Legal/cat/confidentialite.vue";
import Securite from "@/components/Legal/cat/securite.vue";
import Utilisation from "@/components/Legal/cat/utilisation.vue";
import Author from "@/components/Legal/cat/author.vue";
import Message from "@/components/Legal/cat/message.vue";
import Cookies from "@/components/Legal/cat/cookies.vue";

const tabs = ref([
  { name: 'Politique de confidentialité', component: Confidentialite, current: true },
  { name: "Conditions d'utilisation", component: Utilisation, current: false },
  { name: 'Politique de cookies', component: Cookies, current: false },
  { name: "Politique de gestion des droits d'auteur", component: Author, current: false },
  { name: 'Conditions de la messagerie', component: Message, current: false },
  { name: 'Politique de sécurité', component: Securite, current: false },
]);

const selectedTab = ref(tabs.value.find(tab => tab.current).component);

const selectTab = (tab) => {
  tabs.value.forEach(t => t.current = false);
  tab.current = true;
  selectedTab.value = tab.component;
};
</script>

<template>
  <div class="py-28 w-full bg-[#072653] min-h-[450px] mt-[136px] lg:mt-[160px]">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">POLITIQUES DU SITE FNMM</h2>
        <p class="mt-6 text-lg/8 text-gray-200">Consultez l'ensemble des politiques du site, notamment celles relatives à la confidentialité, aux conditions d'utilisation, aux cookies, aux droits d'auteur, à la messagerie et à la sécurité.</p>
      </div>
    </div>
  </div>

  <div class="w-full justify-center flex p-6 lg:px-8">
    <div class="grid grid-cols-1 sm:hidden">
      <select
          aria-label="Select a tab"
          class="w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 outline-gray-300 focus:outline-indigo-600"
          @change="selectTab(tabs.find(tab => tab.name === $event.target.value))">
        <option v-for="tab in tabs" :key="tab.name" :value="tab.name" :selected="tab.current">
          {{ tab.name }}
        </option>
      </select>
      <ChevronDownIcon class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500" aria-hidden="true" />
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <a
              v-for="tab in tabs"
              :key="tab.name"
              href="#"
              @click.prevent="selectTab(tab)"
              :class="[tab.current ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium']"
              :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
  </div>

  <component :is="selectedTab" />
</template>
